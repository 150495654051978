// utils
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { getBrowserName } from 'utils';
import { getAuthData } from 'utils/authData';
// types
import { saveUserDevice } from 'requests/users';
// constants
import { WEB_PUSH_ID, EP_API_URL } from '../constants';
// types
import { TokenType } from 'types';

const AVAILABLE_BROWSERS = ['chrome', 'safari', 'firefox', 'edge'];

const saveTokenToStorage = (token: string) => {
  localStorage.setItem('deviceToken', JSON.stringify(token));
};

export const getDeviceToken = () => {
  return JSON.parse(localStorage.getItem('deviceToken') as string);
};

export const removeTokenFromStorage = () => {
  localStorage.setItem('deviceToken', JSON.stringify(''));
};

export const removeTokenFromServer = () => {
  const currentToken = getDeviceToken();
  const tokens = [
    {
      iid_token: currentToken,
      action: 'destroy',
    },
  ];

  saveUserDevice({ tokens }).then(() => {
    removeTokenFromStorage();
  });
};

const sendTokenToServer = (newToken: string, expiredToken?: string) => {
  const agent = getBrowserName().toLowerCase();
  const tokens: TokenType[] = [
    {
      iid_token: newToken,
      agent: AVAILABLE_BROWSERS.indexOf(agent) !== -1 ? agent : undefined,
      action: 'create',
    },
  ];

  if (expiredToken) {
    tokens.push({ iid_token: expiredToken, action: 'destroy' });
  }

  saveUserDevice({ tokens }).then(() => {
    saveTokenToStorage(newToken);
  });
};

const saveFcmToken = (token: string) => {
  const currentToken = getDeviceToken();

  if (currentToken === token) return;

  sendTokenToServer(token, currentToken);
};

export const initFirebaseMessaging = () => {
  initializeApp({
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID,
  });

  if ('Notification' in window && !('safari' in window)) {
    const messaging = getMessaging();
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        getToken(messaging)
          .then(saveFcmToken)
          .catch(error => {
            console.warn('Unable to get permission to notify.', error);
          });
      }
    });
  }
};

const requestSafariPermission = (permissionData: { permission: string; deviceToken: string }) => {
  if (permissionData.permission === 'default') {
    const authData = getAuthData();

    const endpoint = EP_API_URL + '/api/safari';
    window.safari.pushNotification.requestPermission(
      endpoint,
      WEB_PUSH_ID,
      { uid: authData.uid, token: authData['access-token'], client_id: authData.client },
      requestSafariPermission
    );
    return;
  }
  if (permissionData.permission === 'granted') {
    // The web service URL is a valid push provider, and the user said yes.
    // permissionData.deviceToken is now available to use.
    sendTokenToServer(permissionData.deviceToken);
    return;
  }
  console.warn('User not allowed notifications');
};

export function checkSafariPermission() {
  if (window.safari?.pushNotification) {
    const permissionData = window.safari.pushNotification.permission(WEB_PUSH_ID);
    const currentToken = getDeviceToken();
    if (
      permissionData.deviceToken !== currentToken ||
      !currentToken ||
      !permissionData.deviceToken
    ) {
      requestSafariPermission(permissionData);
    }
  }
}
