import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import CountSection from '../Sections/CountSection';
import CutLevelSection from '../Sections/CutLevelSection';
import DateSection from '../Sections/DateSection';
import FarmStateSection from '../Sections/FarmStateSection';
import FarmTypeSection from '../Sections/FarmTypeSection';
import GeneticsSection from '../Sections/GeneticsSection';
import LoadTypeSection from '../Sections/LoadTypeSection';
import StatusSection from '../Sections/StatusSection';
import SelectionSection from '../Sections/SelectionSection';
import TextSection from '../Sections/TextSection';
import TreatmentProductsSection from '../Sections/TreatmentProductsSection';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as FarmIcon } from 'icons/farm.svg';
import { ReactComponent as GeneticIcon } from 'icons/ep-dna.svg';
import { ReactComponent as PiggyIcon } from 'icons/piggy.svg';
import { ReactComponent as TreatmentIcon } from 'icons/treatment.svg';
import { ReactComponent as WeightIcon } from 'icons/weight.svg';
// utils, constants, hooks
import useWeightHelper from 'utils/hooks/useWeightHelper';
import { getSectionConditions, getSectionConditionTranslations } from 'utils/userFilterHelper';
import { looseSowHousedOptions } from 'utils/constants/userFilter';
// types
import { SectionNames, UserFilterSectionCondition, UserFilterSectionOptions } from 'types';
import { StateUserFilterSection } from 'types/filterSections';

type FilterSectionSwitcherProps = {
  customProps?: {
    isMaleGenetics?: boolean;
  };
  isDisabled?: boolean;
  section: StateUserFilterSection;
  onOptionSelect: (
    value: UserFilterSectionCondition,
    options?: UserFilterSectionOptions,
    other?: Record<string, any>
  ) => void;
  onRemove: () => void;
};

const FilterSectionSwitcher = ({
  customProps,
  isDisabled = false,
  section,
  onOptionSelect,
  onRemove,
}: FilterSectionSwitcherProps) => {
  const { weightLabel } = useWeightHelper();

  function getProps<T extends StateUserFilterSection>(filterSection: T) {
    return {
      isDisabled,
      conditions: getSectionConditions(filterSection.type),
      labelKeys: getSectionConditionTranslations(filterSection.type),
      section: filterSection,
      onOptionSelect,
      onRemove,
    };
  }

  switch (section.type) {
    case SectionNames.Capacity:
    case SectionNames.ContractCommitmentHeadCount:
    case SectionNames.DeadOnArrival:
    case SectionNames.HeadCount:
    case SectionNames.PigsCount:
    case SectionNames.ProjectedInventory:
      return (
        <CountSection
          {...getProps(section)}
          inputLabel={<FormattedMessage id="general.pigs" />}
          triggerIcon={PiggyIcon}
        />
      );
    case SectionNames.EstimatedDaysOnFeed:
      return (
        <CountSection
          {...getProps(section)}
          inputLabel={<FormattedMessage id="general.days" />}
          triggerIcon={CalendarIcon}
        />
      );
    case SectionNames.PigGroupEstimatedWeight:
    case SectionNames.SalesResultAvgPigWeight:
    case SectionNames.Weight:
      return (
        <CountSection
          {...getProps(section)}
          inputLabel={weightLabel}
          measure={weightLabel}
          triggerIcon={WeightIcon}
        />
      );
    case SectionNames.CutLevel:
      return <CutLevelSection {...getProps(section)} />;
    case SectionNames.FarmType:
      return <FarmTypeSection {...getProps(section)} />;
    case SectionNames.FarmState:
      return <FarmStateSection {...getProps(section)} />;
    case SectionNames.LoadType:
      return <LoadTypeSection {...getProps(section)} />;
    case SectionNames.Status:
    case SectionNames.TruckingCompanyStatus: {
      return <StatusSection {...getProps(section)} />;
    }
    case SectionNames.ArriveAt:
    case SectionNames.BaseDate:
    case SectionNames.ContractDate:
    case SectionNames.DeliveredAt:
    case SectionNames.FirstSaleDate:
    case SectionNames.LastArriveAt:
    case SectionNames.LastSaleDate:
    case SectionNames.ScheduleToEmptyDate:
      return <DateSection {...getProps(section)} />;
    case SectionNames.Barn:
    case SectionNames.Contract:
    case SectionNames.ContractCommitment:
    case SectionNames.ContractNames:
    case SectionNames.ContractOwnerCompany:
    case SectionNames.DestinationEntityNames:
    case SectionNames.DestinationPigGroupNames:
    case SectionNames.ExternalSource:
    case SectionNames.Farm:
    case SectionNames.LastPackingPlant:
    case SectionNames.Loading:
    case SectionNames.PackingPlant:
    case SectionNames.PigGroup:
    case SectionNames.SourceEntityNames:
    case SectionNames.SourcePigGroupNames:
    case SectionNames.TruckingCompany:
      return <TextSection {...getProps(section)} />;
    case SectionNames.LooseSowHoused: {
      return (
        <SelectionSection
          condition={section.condition}
          isDisabled={isDisabled}
          isInitialOpened={section.isInitialOpened}
          isInvalid={section.isInvalid}
          onOptionSelect={onOptionSelect}
          onRemove={onRemove}
          placeholder={<FormattedMessage id="general.looseSowHoused" />}
          listOptions={looseSowHousedOptions}
          triggerIcon={FarmIcon}
        />
      );
    }
    case SectionNames.PigGroupGenetics:
      return (
        <GeneticsSection
          {...getProps(section)}
          triggerIcon={GeneticIcon}
          isMaleGenetics={customProps?.isMaleGenetics}
        />
      );
    case SectionNames.PigGroupTreatmentProducts:
      return <TreatmentProductsSection {...getProps(section)} triggerIcon={TreatmentIcon} />;
    default:
      return null;
  }
};

export default FilterSectionSwitcher;
