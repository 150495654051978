import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import ReadMoreComment from 'components/ReadMoreComment';
import LabelValueRow from 'components/LabelValueRow';
import UserAvatar from 'components/UserAvatar';
import Translation from 'components/Translation';
// types
import { LoadSectionProps } from '../../LoadSections.types';

const CommentSection: React.FC<LoadSectionProps> = ({ load, className }) => (
  <div className={className}>
    <LabelValueRow
      label={<FormattedMessage id="general.creator" />}
      value={
        load.modified_by && (
          <>
            <UserAvatar
              size="small"
              name="user_avatar"
              srcData={load.modified_by.profile_photo}
              className="mr-10"
            />
            {load.modified_by.full_name}
          </>
        )
      }
    />
    {!!load.internal_comment && (
      <div className="mt-15">
        <span className="bold">
          <FormattedMessage id="general.internalNote" />
        </span>
        <div className="mt-10">
          <Translation text={load.internal_comment}>
            <ReadMoreComment comment={load.internal_comment} />
          </Translation>
        </div>
      </div>
    )}
  </div>
);

export default CommentSection;
