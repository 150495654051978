import { getBarnColumnsData } from './worksheetBarnsColumns';
import { getGroupsColumnsData } from './pigGroupsColumns';
import {
  getConfirmArrivalPlanLoadsColumns,
  getConfirmTransferPlanLoadsColumns,
  getConfirmSalePlanLoadsColumns,
} from './planConfirmColumns';
import { getLoadsColumns } from './loadsColumns';
import { getSalesResultsColumns } from './salesResultsColumns';
// types
import { TableColumnsType, FormatMessageFunc, ManageColumnNamesByType } from 'types';

export const availColumnsNames = [
  'arrivalBarns',
  'transferBarns',
  'worksheetGroups',
  'confirmArrivalPlanLoads',
  'confirmSalePlanLoads',
  'confirmTransferPlanLoads',
  'loads',
  'loadArchive',
  'salesResults',
  'loadResults',
];

export function getInitialColumnKeys(tableName: TableColumnsType) {
  const initialColumns: Record<string, ManageColumnNamesByType> = {
    worksheetGroups: {
      fixed: ['groupName', 'farmName'],
      manageable: [
        'inventory',
        'projectedInventory',
        'groupAvgWeight',
        'recentSaleAvgWeight',
        'daysOnFeed',
        'firstSaleOn',
        'lastSaleOn',
        'lastPackingPlant',
        'cutLevel',
        'externalSource',
        'maleGeneticNames',
        'looseSowHoused',
      ],
      hidden: [],
      unmanageable: ['user'],
    },
    arrivalBarns: {
      fixed: ['farm', 'barn'],
      manageable: ['farm_state', 'groups', 'placed', 'space', 'scheduled_to_empty'],
      hidden: [],
      unmanageable: ['user'],
    },
    transferBarns: {
      fixed: ['farm', 'barn'],
      manageable: ['farm_state', 'groups', 'space', 'matches_count', 'scheduled_to_empty'],
      hidden: [],
      unmanageable: ['user'],
    },
    confirmArrivalPlanLoads: {
      fixed: ['loadName'],
      manageable: [
        'status',
        'multiLoadDate',
        'contractNames',
        'originNames',
        'destinationFarms',
        'truckingName',
        'targetHeads',
        'avgWeights',
      ],
      hidden: [],
      unmanageable: [],
    },
    confirmTransferPlanLoads: {
      fixed: ['loadName'],
      manageable: [
        'status',
        'multiLoadDate',
        'originNames',
        'destinationFarms',
        'sourceGroups',
        'destinationGroups',
        'truckingName',
        'targetHeads',
        'avgWeights',
      ],
      hidden: [],
      unmanageable: [],
    },
    confirmSalePlanLoads: {
      fixed: ['loadName'],
      manageable: [
        'status',
        'multiLoadDateTime',
        'plantNames',
        'contractNames',
        'originNames',
        'truckingName',
        'targetHeads',
        'avgWeights',
      ],
      hidden: [],
      unmanageable: [],
    },
    loads: {
      fixed: [],
      manageable: [
        'loadName',
        'loadType',
        'status',
        'truckerStatus',
        'departureCaregiverStatus',
        'arrivalCaregiverStatus',
        'pickupTime',
        'arrivalTime',
        'contractNames',
        'originNotes',
        'destinationNotes',
        'internalNote',
        'originNames',
        'originGroupNames',
        'destinationNames',
        'destinationGroupNames',
        'truckingName',
        'targetHeads',
        'scheduledHeads',
        'deliveredHeads',
        'avgWeights',
        'prelimWeights',
      ],
      hidden: [],
      unmanageable: ['user'],
    },
    loadArchive: {
      fixed: ['loadName', 'loadType'],
      manageable: [
        'status',
        'truckerStatus',
        'departureCaregiverStatus',
        'arrivalCaregiverStatus',
        'pickupTime',
        'arrivalTime',
        'contractNames',
        'originNames',
        'originGroupNames',
        'destinationNames',
        'destinationGroupNames',
        'truckingName',
        'targetHeads',
        'scheduledHeads',
        'deliveredHeads',
        'avgWeights',
        'prelimWeights',
      ],
      hidden: [],
      unmanageable: ['user'],
    },
    loadResults: {
      fixed: ['loadName', 'loadType'],
      manageable: [
        'status',
        'truckerStatus',
        'departureCaregiverStatus',
        'arrivalCaregiverStatus',
        'pickupTime',
        'arrivalTime',
        'contractNames',
        'originNames',
        'originGroupNames',
        'destinationNames',
        'destinationGroupNames',
        'truckingName',
        'targetHeads',
        'scheduledHeads',
        'deliveredHeads',
        'avgWeights',
        'prelimWeights',
      ],
      hidden: [],
      unmanageable: ['user'],
    },
    salesResults: {
      fixed: ['loadName'],
      manageable: [
        'targetHead',
        'headCount',
        'headDelivered',
        'avgPigWeight',
        'prelimWeight',
        'cutLevel',
        'coefficientOfVariation',
        'deadOnArrival',
        'plantName',
        'contractName',
        'originName',
        'originGroup',
        'truckingName',
        'deliveryDateTime',
        'status',
      ],
      hidden: [],
      unmanageable: ['user'],
    },
  };
  return initialColumns[tableName];
}

export function getColumnsByType(tableName: TableColumnsType, fm: FormatMessageFunc) {
  switch (tableName) {
    case 'arrivalBarns':
    case 'transferBarns':
      return getBarnColumnsData(fm);
    case 'confirmArrivalPlanLoads':
      return getConfirmArrivalPlanLoadsColumns(fm);
    case 'confirmSalePlanLoads':
      return getConfirmSalePlanLoadsColumns(fm);
    case 'confirmTransferPlanLoads':
      return getConfirmTransferPlanLoadsColumns(fm);
    case 'loadArchive':
    case 'loadResults':
    case 'loads':
      return getLoadsColumns(fm);
    case 'salesResults':
      return getSalesResultsColumns(fm);
    case 'worksheetGroups':
      return getGroupsColumnsData(fm);
  }
}
