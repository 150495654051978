import React from 'react';
// components
import DateTimeInput from '../Input/DateTimeInput';
import { FormattedMessage } from 'react-intl';
// utils
import {
  formatHour24To12h,
  formatHour12To24h,
  isBeforeNoon,
  MAX_HOUR_NUMBER,
} from 'utils/formatDateHelper';
import cn from 'classnames';
// types
import { DateTimeRangeTypes } from './DateTimeRange.types';
// styles
import styles from './DateTimeRange.module.scss';

const DateTimeRange = ({
  className = '',
  hourInputName = 'hour',
  hourValue, // must be 24h format
  minuteInputName = 'minute',
  minuteValue,
  setValues,
  isDisabled,
  isFocused,
}: DateTimeRangeTypes) => {
  const isAM = isBeforeNoon(hourValue);
  const formattedHourValue = formatHour24To12h(hourValue);

  const setDateTimeValue = (value: number | string, name: string) => {
    setValues({ [name]: value ?? '' });
  };

  const setHourValue = (value: number | string, name: string) => {
    setValues({ [name]: formatHour12To24h(Number(value ?? 0), isAM) ?? '' });
  };

  const onAMClick = () => {
    if (isAM || isDisabled) return;
    setValues({
      [hourInputName]: formatHour12To24h(formattedHourValue, true),
      [minuteInputName]: minuteValue || 0,
    });
  };

  const onPMClick = () => {
    if (!isAM || isDisabled) return;
    setValues({
      [hourInputName]: formatHour12To24h(formattedHourValue) || MAX_HOUR_NUMBER,
      [minuteInputName]: minuteValue || 0,
    });
  };

  return (
    <div className={cn(styles['date-fields'], { [className]: !!className })}>
      <DateTimeInput
        disabled={isDisabled}
        name={hourInputName}
        className={styles['required-head-input']}
        value={formattedHourValue ?? ''}
        setValue={setHourValue}
        autoFocus={isFocused}
        min={1}
        max={MAX_HOUR_NUMBER}
      />
      <span className="mh-10">:</span>
      <DateTimeInput
        disabled={isDisabled}
        name={minuteInputName}
        className={styles['required-head-input']}
        value={minuteValue ?? ''}
        setValue={setDateTimeValue}
        max={59}
      />
      <div
        className={cn(styles['time-format'], 'ml-20 mr-5', {
          [styles.active]: isAM,
          [styles.disabled]: isDisabled,
        })}
        onClick={onAMClick}
      >
        <FormattedMessage id="general.time.am" />
      </div>
      <div
        className={cn(styles['time-format'], 'mr-20 ml-5', {
          [styles.active]: !isAM,
          [styles.disabled]: isDisabled,
        })}
        onClick={onPMClick}
      >
        <FormattedMessage id="general.time.pm" />
      </div>
    </div>
  );
};

export default DateTimeRange;
