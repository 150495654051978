import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// assets
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as CheckCircleIcon } from 'icons/check-circle.svg';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';
// utils
import isEqual from 'lodash.isequal';
import {
  availSectionConditions,
  dateOptions,
  extendedDateOptions,
  weightSections,
} from './constants/userFilter';
// types
import {
  ExistingUserFilter,
  MeasurementSystemType,
  ReduxUserFilter,
  SectionNames,
  StateUserFilter,
  UserFilterSectionCondition,
  UserFilterSectionType,
} from 'types';
import { DestroySectionParams } from 'types/handlers';

function isNotEmptyString(value: string | number | (string | number)[]) {
  return typeof value === 'string' && value !== '';
}

export function formatSectionOptionsValues(
  options?: { key: string; value: string | string[] }[] | null
) {
  const optionValues: Record<string, string | string[]> = {};
  options?.forEach(({ key, value }) => {
    optionValues[key] = value;
  });
  return optionValues;
}

export function formatWeightSectionOptions<
  T extends {
    id?: number;
    _destroy?: boolean;
    type?: UserFilterSectionType;
    condition?: UserFilterSectionCondition;
    options?: { key: string; value: string | string[] }[] | null;
  }
>(
  section: T,
  measurementSystem: MeasurementSystemType,
  weightFormatter: (value: number, system: MeasurementSystemType) => number
): T['options'] | [] {
  if (section.type && weightSections.includes(section.type) && section.condition !== 'any') {
    return (
      section.options?.map(item => ({
        ...item,
        value: isNotEmptyString(item.value)
          ? weightFormatter(+item.value, measurementSystem).toString()
          : item.value,
      })) || []
    );
  }
  return section.options || [];
}

export function convertExistingFilterToState(
  userFilter: ExistingUserFilter | undefined
): StateUserFilter {
  return {
    id: userFilter?.id,
    name: userFilter?.name,
    any_condition: userFilter?.any_condition || false,
    sections:
      userFilter?.sections?.map((section, index) => ({
        ...section,
        sectionIndex: index + 1,
      })) || [],
  };
}

export function prepareFilter<T extends ExistingUserFilter>(
  filter: T,
  system: MeasurementSystemType,
  weightFormatter: (value: number, system: MeasurementSystemType) => number
): T {
  return {
    ...filter,
    sections: filter.sections.map(section => ({
      ...section,
      options: formatWeightSectionOptions(section, system, weightFormatter),
    })),
  };
}

export function getLabelComponent(lastKey: string) {
  return <FormattedMessage id={lastKey} />;
}

export function getSectionSvgIcon(sectionType: UserFilterSectionType) {
  switch (sectionType) {
    case SectionNames.ArriveAt:
    case SectionNames.LastArriveAt:
      return CalendarIcon;
    case SectionNames.Loading:
    case SectionNames.LoadType:
      return TruckIcon;
    case SectionNames.TruckingCompanyStatus:
      return CheckCircleIcon;
    default:
      return null;
  }
}

export function getSectionConditions(sectionType: UserFilterSectionType) {
  switch (sectionType) {
    case SectionNames.ArriveAt:
    case SectionNames.ContractDate:
    case SectionNames.DeliveredAt:
    case SectionNames.FirstSaleDate:
    case SectionNames.LastArriveAt:
    case SectionNames.LastSaleDate:
    case SectionNames.ScheduleToEmptyDate:
      return dateOptions;
    case SectionNames.BaseDate:
      return extendedDateOptions;
    case SectionNames.Capacity:
    case SectionNames.ContractCommitmentHeadCount:
    case SectionNames.DeadOnArrival:
    case SectionNames.EstimatedDaysOnFeed:
    case SectionNames.HeadCount:
    case SectionNames.ProjectedInventory:
    case SectionNames.PigsCount:
    case SectionNames.PigGroupEstimatedWeight:
    case SectionNames.SalesResultAvgPigWeight:
    case SectionNames.Weight:
      return [
        availSectionConditions.greaterThan,
        availSectionConditions.lessThan,
        availSectionConditions.is,
        availSectionConditions.isNot,
        availSectionConditions.hasRange,
      ];
    case SectionNames.CutLevel:
    case SectionNames.FarmType:
      return [
        availSectionConditions.is,
        availSectionConditions.isNot,
        availSectionConditions.hasAnyValue,
        availSectionConditions.isUnknown,
      ];
    case SectionNames.FarmState:
      return [
        availSectionConditions.is,
        availSectionConditions.isNot,
        availSectionConditions.startsWith,
        availSectionConditions.endsWith,
        availSectionConditions.contains,
        availSectionConditions.doesNotContain,
        availSectionConditions.hasAnyValue,
      ];
    case SectionNames.LoadType:
    case SectionNames.Status:
    case SectionNames.TruckingCompanyStatus:
      return [
        availSectionConditions.is,
        availSectionConditions.isNot,
        availSectionConditions.hasAnyValue,
      ];
    case SectionNames.Barn:
    case SectionNames.Contract:
    case SectionNames.ContractCommitment:
    case SectionNames.ContractNames:
    case SectionNames.ContractOwnerCompany:
    case SectionNames.DestinationEntityNames:
    case SectionNames.DestinationPigGroupNames:
    case SectionNames.ExternalSource:
    case SectionNames.Farm:
    case SectionNames.LastPackingPlant:
    case SectionNames.Loading:
    case SectionNames.PackingPlant:
    case SectionNames.PigGroup:
    case SectionNames.SourceEntityNames:
    case SectionNames.SourcePigGroupNames:
    case SectionNames.TruckingCompany:
      return [
        availSectionConditions.startsWith,
        availSectionConditions.endsWith,
        availSectionConditions.contains,
        availSectionConditions.doesNotContain,
      ];
    case SectionNames.PigGroupGenetics:
    case SectionNames.PigGroupTreatmentProducts:
      return [
        availSectionConditions.containsAllOf,
        availSectionConditions.containsAnyOf,
        availSectionConditions.containsNoneOf,
      ];
    default:
      return [];
  }
}

export function getSectionConditionTranslations(sectionType: UserFilterSectionType) {
  switch (sectionType) {
    case SectionNames.ArriveAt:
    case SectionNames.LastArriveAt:
      return {
        lt_days_ago: 'filters.dateMoreThan',
        eq_days_ago: 'filters.dateExactly',
        gt_days_ago: 'filters.dateLessThan',
        gt: 'filters.dateAfter',
        eq: 'filters.dateOn',
        lt: 'filters.dateBefore',
        range: 'filters.dateRange',
      };
    case SectionNames.BaseDate:
      return {
        lt_days_ago: 'filters.loadDateMoreThan',
        eq_days_ago: 'filters.loadDateExactly',
        gt_days_ago: 'filters.loadDateLessThan',
        lt_days_in: 'filters.loadDateLessThanNext',
        eq_days_in: 'filters.loadDateExactlyNext',
        gt_days_in: 'filters.loadDateMoreThanNext',
        gt: 'filters.loadDateAfter',
        eq: 'filters.loadDateOn',
        lt: 'filters.loadDateBefore',
        range: 'filters.loadDateRange',
      };
    case SectionNames.ContractDate:
      return {
        lt_days_ago: 'filters.loadDateMoreThan',
        eq_days_ago: 'filters.loadDateExactly',
        gt_days_ago: 'filters.loadDateLessThan',
        gt: 'filters.loadDateAfter',
        eq: 'filters.loadDateOn',
        lt: 'filters.loadDateBefore',
        range: 'filters.loadDateRange',
      };
    case SectionNames.DeliveredAt:
      return {
        lt_days_ago: 'filters.deliveryDateMoreThan',
        eq_days_ago: 'filters.deliveryDateExactly',
        gt_days_ago: 'filters.deliveryDateLessThan',
        gt: 'filters.deliveryDateAfter',
        eq: 'filters.deliveryDateOn',
        lt: 'filters.deliveryDateBefore',
        range: 'filters.deliveryDateRange',
      };
    case SectionNames.LastSaleDate:
      return {
        lt_days_ago: 'filters.lastSaleDateMoreThan',
        eq_days_ago: 'filters.lastSaleDateExactly',
        gt_days_ago: 'filters.lastSaleDateLessThan',
        gt: 'filters.lastSaleDateAfter',
        eq: 'filters.lastSaleDateOn',
        lt: 'filters.lastSaleDateBefore',
        range: 'filters.lastSaleDateRange',
      };
    case SectionNames.FirstSaleDate:
      return {
        lt_days_ago: 'filters.firstSaleDateMoreThan',
        eq_days_ago: 'filters.firstSaleDateExactly',
        gt_days_ago: 'filters.firstSaleDateLessThan',
        gt: 'filters.firstSaleDateAfter',
        eq: 'filters.firstSaleDateOn',
        lt: 'filters.firstSaleDateBefore',
        range: 'filters.firstSaleDateRange',
      };
    case SectionNames.ScheduleToEmptyDate:
      return {
        lt_days_ago: 'filters.scheduledToEmptyDateMoreThan',
        eq_days_ago: 'filters.scheduledToEmptyDateExactly',
        gt_days_ago: 'filters.scheduledToEmptyDateLessThan',
        gt: 'filters.scheduledToEmptyDateAfter',
        eq: 'filters.scheduledToEmptyDateOn',
        lt: 'filters.scheduledToEmptyDateBefore',
        range: 'filters.scheduledToEmptyDateRange',
      };
    case SectionNames.CutLevel:
      return {
        eq: 'filters.cutLevelIs',
        not_eq: 'filters.cutLevelIsNot',
        any: 'filters.cutLevelHasAnyValue',
        none: 'filters.cutLevelNone',
      };
    case SectionNames.FarmType:
      return {
        eq: 'filters.farmTypeIs',
        not_eq: 'filters.farmTypeIsNot',
        any: 'filters.farmTypeHasAnyValue',
        none: 'filters.farmTypeIsUnknown',
      };
    case SectionNames.Status:
    case SectionNames.TruckingCompanyStatus:
      return {
        eq: 'filters.loadStatusIs',
        not_eq: 'filters.loadStatusIsNot',
        any: 'filters.loadStatusHasAnyValue',
      };
    case SectionNames.LoadType:
      return {
        eq: 'filters.loadTypeIs',
        not_eq: 'filters.loadTypeIsNot',
        any: 'filters.loadTypeHasAnyValue',
      };
    case SectionNames.PackingPlant:
      return {
        start: 'filters.plantStartsWith',
        end: 'filters.plantEndsWith',
        cont: 'filters.plantContains',
        not_cont: 'filters.plantDoesNotContain',
      };
    case SectionNames.LastPackingPlant:
      return {
        start: 'filters.lastPlantStartsWith',
        end: 'filters.lastPlantEndsWith',
        cont: 'filters.lastPlantContains',
        not_cont: 'filters.lastPlantDoesNotContain',
      };
    case SectionNames.ContractOwnerCompany:
      return {
        start: 'filters.contractOwnerStartsWith',
        end: 'filters.contractOwnerEndsWith',
        cont: 'filters.contractOwnerContains',
        not_cont: 'filters.contractOwnerDoesNotContain',
      };
    case SectionNames.Contract:
    case SectionNames.ContractNames:
      return {
        start: 'filters.contractStartsWith',
        end: 'filters.contractEndsWith',
        cont: 'filters.contractContains',
        not_cont: 'filters.contractDoesNotContain',
      };
    case SectionNames.ContractCommitment:
    case SectionNames.Loading:
      return {
        start: 'filters.loadStartsWith',
        end: 'filters.loadEndsWith',
        cont: 'filters.loadContains',
        not_cont: 'filters.loadDoesNotContain',
      };
    case SectionNames.TruckingCompany:
      return {
        start: 'filters.truckingStartsWith',
        end: 'filters.truckingEndsWith',
        cont: 'filters.truckingContains',
        not_cont: 'filters.truckingDoesNotContain',
      };
    case SectionNames.PigGroup:
      return {
        start: 'filters.groupStartsWith',
        end: 'filters.groupEndsWith',
        cont: 'filters.groupContains',
        not_cont: 'filters.groupDoesNotContain',
      };
    case SectionNames.Farm:
      return {
        start: 'filters.farmStartsWith',
        end: 'filters.farmEndsWith',
        cont: 'filters.farmContains',
        not_cont: 'filters.farmDoesNotContain',
      };
    case SectionNames.Barn:
      return {
        start: 'filters.barnStartsWith',
        end: 'filters.barnEndsWith',
        cont: 'filters.barnContains',
        not_cont: 'filters.barnDoesNotContain',
      };
    case SectionNames.ExternalSource:
      return {
        start: 'filters.externalSourceStartsWith',
        end: 'filters.externalSourceEndsWith',
        cont: 'filters.externalSourceContains',
        not_cont: 'filters.externalSourceDoesNotContain',
      };
    case SectionNames.DestinationEntityNames:
      return {
        start: 'filters.destinationStartsWith',
        end: 'filters.destinationEndsWith',
        cont: 'filters.destinationContains',
        not_cont: 'filters.destinationDoesNotContain',
      };
    case SectionNames.DestinationPigGroupNames:
      return {
        start: 'filters.destinationGroupStartsWith',
        end: 'filters.destinationGroupEndsWith',
        cont: 'filters.destinationGroupContains',
        not_cont: 'filters.destinationGroupDoesNotContain',
      };
    case SectionNames.SourceEntityNames:
      return {
        start: 'filters.originStartsWith',
        end: 'filters.originEndsWith',
        cont: 'filters.originContains',
        not_cont: 'filters.originDoesNotContain',
      };
    case SectionNames.SourcePigGroupNames:
      return {
        start: 'filters.originGroupStartsWith',
        end: 'filters.originGroupEndsWith',
        cont: 'filters.originGroupContains',
        not_cont: 'filters.originGroupDoesNotContain',
      };
    case SectionNames.FarmState:
      return {
        eq: 'filters.farmStateIs',
        not_eq: 'filters.farmStateIsNot',
        start: 'filters.farmStateStartsWith',
        end: 'filters.farmStateEndsWith',
        cont: 'filters.farmStateContains',
        not_cont: 'filters.farmStateDoesNotContain',
        any: 'filters.farmStateHasAnyValue',
      };
    case SectionNames.EstimatedDaysOnFeed:
      return {
        gt: 'filters.dofGreaterThan',
        lt: 'filters.dofLessThan',
        eq: 'filters.dofIs',
        not_eq: 'filters.dofIsNot',
        range: 'filters.dofHasRange',
      };
    case SectionNames.ContractCommitmentHeadCount:
      return {
        gt: 'filters.contractHeadCountGreaterThan',
        lt: 'filters.contractHeadCountLessThan',
        eq: 'filters.contractHeadCountIs',
        not_eq: 'filters.contractHeadCountIsNot',
        range: 'filters.contractHeadCountHasRange',
      };
    case SectionNames.DeadOnArrival:
      return {
        gt: 'filters.deadOnArrivalGreaterThan',
        lt: 'filters.deadOnArrivalLessThan',
        eq: 'filters.deadOnArrivalIs',
        not_eq: 'filters.deadOnArrivalIsNot',
        range: 'filters.deadOnArrivalHasRange',
      };
    case SectionNames.HeadCount:
      return {
        gt: 'filters.headCountGreaterThan',
        lt: 'filters.headCountLessThan',
        eq: 'filters.headCountIs',
        not_eq: 'filters.headCountIsNot',
        range: 'filters.headCountHasRange',
      };
    case SectionNames.Capacity:
      return {
        gt: 'filters.capacityGreaterThan',
        lt: 'filters.capacityLessThan',
        eq: 'filters.capacityIs',
        not_eq: 'filters.capacityIsNot',
        range: 'filters.capacityHasRange',
      };
    case SectionNames.ProjectedInventory:
      return {
        gt: 'filters.projectedInventoryGreaterThan',
        lt: 'filters.projectedInventoryLessThan',
        eq: 'filters.projectedInventoryIs',
        not_eq: 'filters.projectedInventoryIsNot',
        range: 'filters.projectedInventoryHasRange',
      };
    case SectionNames.PigsCount:
      return {
        gt: 'filters.inventoryGreaterThan',
        lt: 'filters.inventoryLessThan',
        eq: 'filters.inventoryIs',
        not_eq: 'filters.inventoryIsNot',
        range: 'filters.inventoryHasRange',
      };
    case SectionNames.PigGroupEstimatedWeight:
      return {
        gt: 'filters.avgWeightGreaterThan',
        lt: 'filters.avgWeightLessThan',
        eq: 'filters.avgWeightIs',
        not_eq: 'filters.avgWeightIsNot',
        range: 'filters.avgWeightHasRange',
      };
    case SectionNames.SalesResultAvgPigWeight:
      return {
        gt: 'filters.prelimWeightGreaterThan',
        lt: 'filters.prelimWeightLessThan',
        eq: 'filters.prelimWeightIs',
        not_eq: 'filters.prelimWeightIsNot',
        range: 'filters.prelimWeightHasRange',
      };
    case SectionNames.Weight:
      return {
        gt: 'filters.weightGreaterThan',
        lt: 'filters.weightLessThan',
        eq: 'filters.weightIs',
        not_eq: 'filters.weightIsNot',
        range: 'filters.weightHasRange',
      };
    case SectionNames.PigGroupTreatmentProducts:
      return {
        cont_all_of: 'filters.treatmentsCount',
        cont_any_of: 'filters.treatmentsCount',
        cont_none_of: 'filters.treatmentsCount',
      };
    case SectionNames.PigGroupGenetics:
      return {
        cont_all_of: 'filters.geneticsCount',
        cont_any_of: 'filters.geneticsCount',
        cont_none_of: 'filters.geneticsCount',
      };
    default:
      return {};
  }
}

export function getSectionsToDelete(
  savedFilter: StateUserFilter,
  currentFilter: StateUserFilter | ReduxUserFilter
) {
  const deletedSections: DestroySectionParams[] = [];
  savedFilter.sections.forEach(savedSection => {
    if (savedSection.id && !currentFilter.sections.find(item => item.id === savedSection.id)) {
      deletedSections.push({ id: savedSection.id, _destroy: true });
    }
  });
  return deletedSections;
}

export function isEqualFilters(currentFilter: StateUserFilter, nextFilter: StateUserFilter) {
  if (currentFilter.any_condition !== nextFilter.any_condition) return false;
  const currentSections = currentFilter.sections || [];
  const nextSections = nextFilter.sections || [];
  if (currentSections.length !== nextSections.length) return false;
  return currentSections.every((section, index) => {
    const { id, condition, type, options } = section;
    const {
      id: nextId,
      condition: nextCondition,
      type: nextType,
      options: nextOptions,
    } = nextSections[index];
    const formattedOptions = formatSectionOptionsValues(options);
    const formattedNextOptions = formatSectionOptionsValues(nextOptions);
    return (
      id === nextId &&
      condition === nextCondition &&
      type === nextType &&
      isEqual(formattedOptions, formattedNextOptions)
    );
  });
}
