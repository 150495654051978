import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
// utils
import cn from 'classnames';
import { isLoadDraft, isLoadProcessing } from 'utils/loadHelper';
// types
import { Load } from 'types';
// styles
import styles from './LoadMatchingForm.module.scss';

type HandlerFunction = () => void;

type LoadFormButtonsProps = {
  applyEditLoad?: HandlerFunction;
  hasEditButtons?: boolean;
  canPublishLoad: boolean;
  load: Load;
  removeEditLoad?: HandlerFunction;
  saveDraft: HandlerFunction;
  saveProcessing: HandlerFunction;
};

const LoadFormButtons = ({
  applyEditLoad,
  canPublishLoad,
  hasEditButtons = false,
  load,
  removeEditLoad,
  saveDraft,
  saveProcessing,
}: LoadFormButtonsProps) => {
  const isDraft = isLoadDraft(load);
  const isProcessing = isLoadProcessing(load);
  const canEdit = hasEditButtons && !!load.edit_loading && !isDraft && !isProcessing;
  return (
    <div className={styles['btn-section']}>
      {isDraft && (
        <Button
          onClick={saveDraft}
          className={cn(styles['submit-btn'], styles.white)}
          rounded
          shine
        >
          <FormattedMessage id="general.btn.saveDraft" />
        </Button>
      )}

      {isDraft && canPublishLoad && (
        <Button onClick={saveProcessing} className={styles['submit-btn']} primary rounded shine>
          <FormattedMessage id="general.btn.scheduleLoad" />
        </Button>
      )}
      {canEdit && (
        <Button onClick={removeEditLoad} className={styles['submit-btn']} rounded shine>
          <FormattedMessage id="general.btn.revertChanges" />
        </Button>
      )}
      {canEdit && canPublishLoad && (
        <Button onClick={applyEditLoad} className={styles['submit-btn']} primary rounded shine>
          <FormattedMessage id="general.btn.applyChanges" />
        </Button>
      )}
    </div>
  );
};

export default LoadFormButtons;
