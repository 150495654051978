import React from 'react';
// components
import FormattedWeight from 'components/FormattedWeight';
// utils
import { DateTime } from 'luxon';
import { formatDate } from './formatDateHelper';
import { get, getWeightRange, getValueFromQuery } from 'utils';
import { addLabelToWeightValue } from './weightHelper';
// types
import {
  AddressEntity,
  CommitmentType,
  CommitmentTypes,
  ConfirmationStatus,
  ContractCommitment,
  FarmPigGroup,
  FormatMessageFunc,
  Load,
  LoadCommitment,
  LoadStatus,
  MovementType,
  ReturnedGroupPigsObject,
  TruckingLoad,
  TruckingLoadCommitment,
} from 'types';
import { Location } from 'history';

const loadUnMatchStatuses = ['processing', 'pending', 'confirmed', 'conflict'];
const loadEditableStatuses = ['pending', 'confirmed', 'conflict', 'in_progress'];
const loadNotEditableStatuses = ['incomplete', 'delivered'];

export const loadDeclineStatuses = ['draft', 'processing', 'pending', 'confirmed'];

export function isLoadCanBeResetOrUnmatched(load: Load) {
  const { edit_loading_id, status, matching_started } = load || {};
  return edit_loading_id
    ? false
    : loadUnMatchStatuses.includes(status) || (status === 'draft' && matching_started);
}

export function isLoadEditable(load: Load) {
  return loadEditableStatuses.includes(load.status);
}

export function isLoadNotEditable(load: Load) {
  return loadNotEditableStatuses.includes(load.status);
}

export function isLoadDraft(load: Load) {
  return load.status === 'draft';
}

export function isLoadProcessing(load: Load) {
  return load.status === 'processing';
}

export function isLoadDraftOrProcessing(load: Load) {
  return isLoadDraft(load) || isLoadProcessing(load);
}

export function isSomeLoadNotConfirmed(planLoads: Load[]) {
  return planLoads.some(isLoadDraftOrProcessing);
}

export function isPlanConfirmable(planLoads: Load[]) {
  return planLoads.some(isLoadProcessing) && !planLoads.some(isLoadDraft);
}

export function isCommitmentMatched(loadCommitment: LoadCommitment) {
  return loadCommitment.matched && !!loadCommitment.arrive_at;
}

export function getCommitmentsValidationData(commitments: LoadCommitment[]) {
  let isValidTimeline = true;
  let isAllCommitmentsMatched = true;
  commitments.forEach(cm => {
    if (!cm.arrive_at) {
      isValidTimeline = false;
    }
    if (!cm.matched) {
      isAllCommitmentsMatched = false;
    }
  });
  return {
    isAllCommitmentsMatched,
    isValidTimeline,
    isReadyToSchedule: isAllCommitmentsMatched && isValidTimeline,
  };
}

export function isTruckingLoadProfilePage(pathname = '') {
  return (
    pathname.match(/trucking\/loads\/[0-9]*/) ||
    pathname.match(/trucking\/schedule\/[0-9]*/) ||
    pathname.match(/trucking\/archive\/[0-9]*/)
  );
}

export function findDestinationCommitment(loadCommitment: LoadCommitment | TruckingLoadCommitment) {
  return loadCommitment.commitment_type === 'destination';
}

export function findSourceCommitment(loadCommitment: LoadCommitment | TruckingLoadCommitment) {
  return loadCommitment.commitment_type === 'source';
}

export function insertCommitmentToIndex(
  index: number,
  commitment: LoadCommitment,
  commitmentsList: LoadCommitment[]
) {
  const commitmentsAfterIndex = commitmentsList
    .slice(index)
    .map(item => ({ ...item, position: item.position + 1 }));
  return [...commitmentsList.slice(0, index), commitment, ...commitmentsAfterIndex];
}

export function removeCommitmentFromIndex(index: number, commitmentsList: LoadCommitment[]) {
  const commitmentsAfterIndex = commitmentsList
    .slice(index + 1)
    .map(item => ({ ...item, position: item.position - 1 }));
  return [...commitmentsList.slice(0, index), ...commitmentsAfterIndex];
}

export function getLoadAssociatedFarmsCount(loads: Load[]) {
  const associatedFarmsIds = new Set();
  loads.forEach(({ commitments }) => {
    commitments.forEach(({ farm }) => {
      if (farm?.id) associatedFarmsIds.add(farm.id);
    });
  });
  return associatedFarmsIds.size;
}

export function isSaleLoad(load: Load | TruckingLoad) {
  return load.load_type === 'sale';
}

export function isTransferLoad(load: Load) {
  return load.load_type === 'transfer';
}

export function isArrivalLoad(load: Load) {
  return load.load_type === 'arrival';
}

export function generateEmptyLoad(type: MovementType, commitment: ContractCommitment): Load {
  return {
    allow_archive: false,
    allow_sales_results_create: false,
    allow_unarchive: false,
    archived: false,
    base_date: commitment.contract_date,
    can_trucking_force_accept: false,
    commitments: [],
    edit_loading_id: null,
    id: 'cc-' + commitment.id,
    internal_comment: null,
    load_type: type,
    matched: false,
    matching_started: true,
    name: commitment.name,
    plan_id: null,
    rate: null,
    rate_type: null,
    skip_trucking_company: true,
    status: 'draft',
    trucking_company_status: null,
  };
}

export function generateEmptyLoadCommitment(
  type: CommitmentType,
  position: number
): LoadCommitment {
  return {
    arrive_at: null,
    barn_id: null,
    can_force_accept: false,
    commitment_type: type,
    comment: null,
    cut_level: null,
    faked: true,
    farm_id: null,
    head_count: null,
    id: `${Math.random()}`,
    matched: false,
    minutes_for_load: null,
    pig_group_assign_name: null,
    pig_group_assign_type: null,
    pig_group_id: null,
    position,
    status: null,
    travel_distance: null,
    travel_seconds: null,
  };
}

export function getCommitmentType(loadType: MovementType) {
  return loadType === 'sale' ? CommitmentTypes.destination : CommitmentTypes.source;
}

export function getMatchableCommitmentType(loadType: MovementType) {
  return loadType === 'sale' ? CommitmentTypes.source : CommitmentTypes.destination;
}

export function getDividedCommitments(commitments: LoadCommitment[]) {
  const sources: LoadCommitment[] = [];
  const destinations: LoadCommitment[] = [];
  commitments.forEach(commitment => {
    if (commitment.commitment_type === CommitmentTypes.source) sources.push(commitment);
    else destinations.push(commitment);
  });
  return {
    sources,
    destinations,
  };
}

export function getHeadCountSumByCommitmentType(commitments: LoadCommitment[]) {
  return commitments.reduce(
    (data, commitment) => {
      let { sourcesHeadCount, destHeadCount } = data;
      if (commitment.commitment_type === 'source') {
        sourcesHeadCount += commitment.head_count || 0;
      }
      if (commitment.commitment_type === 'destination') {
        destHeadCount += commitment.head_count || 0;
      }
      return {
        sourcesHeadCount,
        destHeadCount,
      };
    },
    { sourcesHeadCount: 0, destHeadCount: 0 }
  );
}

export const getLoadTotalByKey = (load: Load | TruckingLoad, key: string) => {
  const type = getCommitmentType(load.load_type);
  let count = 0;
  load.commitments.forEach((commitment: LoadCommitment | TruckingLoadCommitment) => {
    if (commitment.commitment_type === type) {
      count += get(commitment, key) || 0;
    }
  });
  return count;
};

export const getAvailHeadCountForMatch = (
  selectedLoad?: Load,
  selectedCommitment?: LoadCommitment,
  selectedLoads?: Load[]
) => {
  if (selectedLoads?.length) {
    const headsCount = selectedLoads.map(load => getLoadTotalByKey(load, 'head_count'));
    const headsCountSum = headsCount.reduce((prev, next) => prev + next, 0);
    return headsCountSum;
  }

  if (!selectedLoad || !selectedCommitment || selectedCommitment.matched) return 0;
  const matchCommitmentType = getMatchableCommitmentType(selectedLoad?.load_type);
  const commitmentsForMatch = selectedLoad.commitments.filter(
    ({ commitment_type }) => commitment_type === matchCommitmentType
  );
  return commitmentsForMatch.length > 1
    ? selectedCommitment?.head_count || 0
    : getLoadTotalByKey(selectedLoad, 'head_count');
};

export function getLoadTypeOptions(formatMessage: FormatMessageFunc) {
  return [
    { value: 'sale', label: formatMessage({ id: 'loads.loadType.sale' }) },
    { value: 'arrival', label: formatMessage({ id: 'loads.loadType.arrival' }) },
    { value: 'transfer', label: formatMessage({ id: 'loads.loadType.transfer' }) },
  ];
}

export function getLoadActionsStatusOptions(formatMessage: FormatMessageFunc) {
  return [
    { value: 'pending', label: formatMessage({ id: 'status.pending' }) },
    { value: 'accepted', label: formatMessage({ id: 'status.accepted' }) },
    { value: 'declined', label: formatMessage({ id: 'status.declined' }) },
  ];
}

export const sortCommitmentsByPosition = (commitments: LoadCommitment[]) =>
  commitments.slice().sort(({ position: prevPosition = 0 }, { position: nextPosition = 0 }) => {
    if (prevPosition < nextPosition) return -1;
    if (prevPosition > nextPosition) return 1;
    return 0;
  });

export const sortLoadCommitments = (load: Load): Load => ({
  ...load,
  commitments: sortCommitmentsByPosition(load.commitments),
});

export function getLoadPickUpDates(load: Load) {
  return load.commitments
    .filter(({ commitment_type, arrive_at }) => commitment_type === 'source' && arrive_at)
    .map(({ arrive_at }) => formatDate(arrive_at));
}

export function getLoadArrivingDates(load: Load, format: string) {
  const { base_date, load_type, commitments } = load;
  const commitmentType = getCommitmentType(load_type);
  return commitments
    .filter(({ commitment_type }) => commitment_type === commitmentType)
    .map(({ arrive_at }) =>
      arrive_at || base_date ? formatDate(arrive_at || base_date, format) : ''
    );
}

export function getLoadContractNamesByType(load: Load) {
  const { load_type, commitments } = load;
  const commitmentType = getCommitmentType(load_type);
  return commitments
    .filter(({ commitment_type }) => commitment_type === commitmentType)
    .map(({ contract_commitment }) => contract_commitment?.contract?.name || '');
}

export function getLoadPackingPlantNames(load: Load) {
  return load.commitments
    .filter(findDestinationCommitment)
    .map(({ packing_plant }) => packing_plant?.name || '');
}

export function getLoadFarmNames(load: Load, usedCommitmentType: CommitmentType) {
  return load.commitments
    .filter(({ commitment_type, farm }) => commitment_type === usedCommitmentType && farm)
    .map(({ farm }) => farm?.name || '');
}

export function getLoadPigGroupNames(load: Load, usedCommitmentType: CommitmentType) {
  return load.commitments
    .filter(({ commitment_type, pig_group }) => commitment_type === usedCommitmentType && pig_group)
    .map(({ pig_group }) => pig_group?.name || '');
}

export function getLoadPigGroupWeightValues(load: Load, usedCommitmentType: CommitmentType) {
  return load.commitments
    .filter(({ commitment_type, pig_group }) => commitment_type === usedCommitmentType && pig_group)
    .map(({ pig_group }) => addLabelToWeightValue(pig_group?.estimated_weight));
}

export function getLoadContractWeightRanges(load: Load) {
  return load.commitments
    .filter(({ commitment_type }) => commitment_type === getCommitmentType(load.load_type))
    .map(({ contract_commitment }) => {
      return contract_commitment?.contract?.weight_min &&
        contract_commitment?.contract?.weight_max ? (
        <FormattedWeight.Range
          min={contract_commitment?.contract?.weight_min}
          max={contract_commitment?.contract?.weight_max}
        />
      ) : (
        ''
      );
    });
}

export const getContractFromLoad = (load: Load) =>
  load.commitments?.find(commitment => commitment.contract_commitment)?.contract_commitment
    ?.contract;

export const getPlantsFromLoad = (load: Load) =>
  load.commitments
    .filter(commitment => commitment.packing_plant)
    ?.map(({ packing_plant }) => packing_plant);

export const getSowUnitsFromLoad = (load: Load) =>
  load.commitments.filter(commitment => commitment.sow_unit)?.map(({ sow_unit }) => sow_unit);

export const getLoadCommitmentAddressEntity = ({
  sow_unit,
  farm,
  packing_plant,
}: LoadCommitment | TruckingLoadCommitment): AddressEntity | undefined => {
  return sow_unit || farm || packing_plant;
};

export const getUnMatchedDestinationName = (
  destPosition: number | undefined,
  sourcesCount: number,
  fm: FormatMessageFunc
) => {
  return fm({ id: 'loadsTable.destination' }) + ' ' + ((destPosition || 0) + 1 - sourcesCount);
};

export const getUnMatchedSourceName = (
  sourcePosition: number | undefined,
  fm: FormatMessageFunc
) => {
  return fm({ id: 'loadsTable.origin' }) + ' ' + ((sourcePosition || 0) + 1);
};

export const getCommitmentMatchEntityKey = (commitment: LoadCommitment, loadType: MovementType) => {
  return loadType === 'sale' ? 'pig_group' : 'barn';
};

export const getLoadingLoadDate = ({ commitments }: Load | TruckingLoad) =>
  commitments[0].arrive_at;

export const getLoadingDeliveryDate = ({ commitments }: Load | TruckingLoad) =>
  commitments[commitments.length - 1].arrive_at || '';

export const isValidCommitmentDateRange = (
  currCommitment: LoadCommitment,
  prevCommitment?: LoadCommitment
) => {
  if (!currCommitment.arrive_at) return false;
  if (!prevCommitment || !prevCommitment.arrive_at) return true;
  const loadTimeInSeconds = getCommitmentTimeToLoad(prevCommitment) * 60;
  const prevTravelSeconds = prevCommitment?.travel_seconds || 0;
  const start = DateTime.fromISO(prevCommitment.arrive_at);
  const end = DateTime.fromISO(currCommitment.arrive_at);
  const rangeSeconds = end.diff(start, 'seconds').seconds;
  return rangeSeconds >= loadTimeInSeconds + prevTravelSeconds;
};

export function getEntityForSort(load: Load, entity: 'farm' | 'sow_unit' | 'packing_plant') {
  if (entity === 'packing_plant') {
    return load.commitments[load.commitments.length - 1].packing_plant;
  }
  return load.commitments.find(
    commitment => !!commitment[entity] && commitment.commitment_type === CommitmentTypes.source
  )?.[entity];
}

export function getGroupForSort(load: Load, type: CommitmentType) {
  const orderedCommitments =
    type === CommitmentTypes.source ? load.commitments : [...load.commitments].reverse();
  return orderedCommitments.find(
    ({ pig_group, commitment_type }) => commitment_type === type && !!pig_group
  )?.pig_group;
}

export function getContractForSort(load: Load, type: CommitmentType) {
  const orderedCommitments =
    type === CommitmentTypes.source ? load.commitments : [...load.commitments].reverse();
  return orderedCommitments.find(
    ({ commitment_type, contract_commitment }) => commitment_type === type && !!contract_commitment
  )?.contract_commitment?.contract;
}

export function getTargetHeadForSort(load: Load, type: CommitmentType) {
  const orderedCommitments =
    type === CommitmentTypes.source ? load.commitments : [...load.commitments].reverse();
  return orderedCommitments
    .filter(({ commitment_type }) => commitment_type === type)
    .reduce((acc, commitment) => acc + (commitment.head_count || 0), 0);
}

export function sortLoadsByMatched(loads: Load[]) {
  const matchedArr: Load[] = [];
  const preMatchedArr: Load[] = [];
  const unMatchedArr: Load[] = [];
  loads.forEach(load => {
    if (load.matched) return matchedArr.push(load);
    if (load.matching_started) return preMatchedArr.push(load);
    return unMatchedArr.push(load);
  });
  return [...matchedArr, ...preMatchedArr, ...unMatchedArr];
}

export function sortLoadsByArriveAt(loads: Load[]) {
  return [...loads].sort((aLoad, bLoad) => {
    const aDate = DateTime.fromISO(getLoadingDeliveryDate(aLoad));
    const bDate = DateTime.fromISO(getLoadingDeliveryDate(bLoad));
    if (+aDate === +bDate) return 0;
    return aDate > bDate ? 1 : -1;
  });
}

export function sortLoadsByContract(loads: Load[], type: CommitmentType) {
  return [...loads].sort((aLoad, bLoad) => {
    const aFieldName = getContractForSort(aLoad, type)?.name || '';
    const bFieldName = getContractForSort(bLoad, type)?.name || '';
    if (aFieldName < bFieldName) return -1;
    if (aFieldName > bFieldName) return 1;
    return 0;
  });
}

export function sortLoadsByEntityKey(
  loads: Load[],
  entity: 'farm' | 'sow_unit' | 'packing_plant',
  key: 'name' | 'city' | 'state'
) {
  return [...loads].sort((aLoad, bLoad) => {
    const aFieldName = getEntityForSort(aLoad, entity)?.[key] || '';
    const bFieldName = getEntityForSort(bLoad, entity)?.[key] || '';
    if (aFieldName < bFieldName) return -1;
    if (aFieldName > bFieldName) return 1;
    return 0;
  });
}

export function sortLoadsByGroupKey(loads: Load[], type: CommitmentType, key: keyof FarmPigGroup) {
  return [...loads].sort((aLoad, bLoad) => {
    const aFieldName = getGroupForSort(aLoad, type)?.[key] || '';
    const bFieldName = getGroupForSort(bLoad, type)?.[key] || '';
    if (aFieldName < bFieldName) return -1;
    if (aFieldName > bFieldName) return 1;
    return 0;
  });
}

export function sortLoadsByTargetHead(loads: Load[], type: CommitmentType) {
  return [...loads].sort((aLoad, bLoad) => {
    const aFieldName = getTargetHeadForSort(aLoad, type);
    const bFieldName = getTargetHeadForSort(bLoad, type);
    if (aFieldName < bFieldName) return -1;
    if (aFieldName > bFieldName) return 1;
    return 0;
  });
}

export function getWeightRangeLabel(load: Load, fm: FormatMessageFunc, measureLabelKey: string) {
  const sourceGroupWeight =
    load.commitments.find(
      ({ commitment_type, pig_group }) => commitment_type === CommitmentTypes.source && !!pig_group
    )?.pig_group?.estimated_weight || 0;
  return getWeightRange(sourceGroupWeight, 10, fm?.({ id: `general.${measureLabelKey}` }));
}

export function getTruckingLoadActions(load: TruckingLoad, isDriverLoadProfile: boolean) {
  const statusKey = isDriverLoadProfile ? 'driver_status' : 'trucking_company_status';
  return {
    canAccept: load[statusKey] !== 'accepted',
    canDecline: load[statusKey] !== 'declined',
  };
}

export function getTruckingLoadStatus(
  pageUrl: string,
  load?: TruckingLoad
): LoadStatus | NonNullable<ConfirmationStatus> {
  const defaultStatus = 'pending';
  if (pageUrl.startsWith('/trucking/loads')) return load?.trucking_company_status || defaultStatus;
  if (pageUrl.startsWith('/trucking/schedule')) return load?.driver_status || defaultStatus;
  return load?.status || defaultStatus;
}

export function getBackUrl(currentUrl: string, search: string) {
  return (
    getValueFromQuery(search, 'from') ||
    currentUrl
      .split('/')
      .slice(0, 3)
      .join('/')
  );
}

export function getCorrectCommitmentId(load: Load) {
  const isSale = isSaleLoad(load);
  return load.commitments.find(isSale ? findSourceCommitment : findDestinationCommitment)?.id;
}

export function getCorrectSideModalMatchUrl(location: Location, newLoad?: Load) {
  const { pathname, search } = location;
  const urlCommitmentId = getValueFromQuery(search, 'load_commitment_id');
  if (newLoad && !newLoad.commitments.find(({ id }) => id === urlCommitmentId)) {
    const isSale = isSaleLoad(newLoad);
    const commitmentToPush = newLoad.commitments.find(
      isSale ? findSourceCommitment : findDestinationCommitment
    );
    if (commitmentToPush) {
      const matchId = isSale ? commitmentToPush.pig_group?.id : commitmentToPush.barn?.id;
      const search = matchId
        ? `?load_commitment_id=${commitmentToPush.id}&match_id=${matchId}`
        : `?load_commitment_id=${commitmentToPush.id}`;
      return pathname + search;
    }
  }

  return '';
}

export function getCorrectSideModalMatchUrlForMultipleLoads(location: Location, loads?: Load[]) {
  const { pathname } = location;
  if (loads) {
    const isSale = isSaleLoad(loads[0]);
    const commitments = loads.map(({ commitments }) =>
      commitments.find(isSale ? findSourceCommitment : findDestinationCommitment)
    ) as LoadCommitment[];
    const commitmentsIds = commitments.map(({ id }) => id);
    if (commitments) {
      const baseCommitment = commitments[0];
      const matchId = isSale ? baseCommitment.pig_group?.id : baseCommitment.barn?.id;
      const search = matchId
        ? `?load_commitments_ids=${commitmentsIds.join(',')}&match_id=${matchId}`
        : `?load_commitments_ids=${commitmentsIds.join(',')}`;
      return pathname + search;
    }
  }

  return '';
}

export function getReturnedPigsCountByGroup(loads: Load[]): ReturnedGroupPigsObject[] {
  let groupsPigsData: ReturnedGroupPigsObject[] = [];
  loads.forEach(load => {
    const sourceCommitments = load.commitments.filter(findSourceCommitment);
    sourceCommitments.forEach(commitment => {
      const existedData = groupsPigsData.find(
        ({ groupId }) => groupId === commitment.pig_group?.id
      );
      if (!existedData) {
        groupsPigsData.push({
          groupId: commitment.pig_group?.id || '',
          pigs: commitment.head_count || 0,
        });
      } else {
        groupsPigsData = groupsPigsData.map(data => {
          if (data.groupId === existedData.groupId) {
            return { ...data, pigs: data.pigs + (commitment.head_count || 0) };
          }
          return data;
        });
      }
    });
  });
  return groupsPigsData;
}

export function isEntityAlreadyMatched(commitments: LoadCommitment[], currentIndex: number) {
  const prevCommitments = commitments.slice(0, currentIndex);
  const prevMatchedIds = prevCommitments.map(
    cm => cm.packing_plant?.id || cm.sow_unit?.id || cm.pig_group?.id
  );
  const currentCommitment = commitments[currentIndex];
  const currentMatchedId =
    currentCommitment.packing_plant?.id ||
    currentCommitment.sow_unit?.id ||
    currentCommitment.pig_group?.id;
  return prevMatchedIds.includes(currentMatchedId || '');
}

export function getMatchingLoadLink(load: Load, planId: string) {
  let startedPath = {
    arrival: `/scheduling/arrival-plans/${planId}`,
    sale: `/scheduling/sales-plans/${planId}`,
    transfer: `/scheduling/transfer-plans/${planId}`,
  }[load.load_type];

  const matchableCommitment = load.commitments.find(
    isSaleLoad(load) ? findSourceCommitment : findDestinationCommitment
  );
  const commitmentId = matchableCommitment?.id;
  const matchId = matchableCommitment?.barn?.id || matchableCommitment?.pig_group?.id;
  if (commitmentId) {
    startedPath += `?load_commitment_id=${commitmentId}`;
    if (matchId) startedPath += `&match_id=${matchId}`;
  }

  return startedPath;
}

export function getCommitmentTimeToLoad(loadCommitment: LoadCommitment) {
  const { minutes_for_load, farm, sow_unit } = loadCommitment;
  return minutes_for_load ?? (farm?.minutes_for_load || sow_unit?.minutes_for_load || 0);
}
