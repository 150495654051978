import React, { ReactElement, useState } from 'react';
// utils
import cn from 'classnames';
import Loader from 'components/Loader';
// endpoints
import { getTranslation } from 'requests/translations';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// store
import useAuthUserSelector from 'store/selectors/useAuthUserSelector';
// styles
import styles from './Translation.module.scss';

type TranslationProps = {
  text?: string;
  children: ReactElement;
};

const Translation = ({ text = '', children }: TranslationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [textTranslation, setTextTranslation] = useState('');
  const authUser = useAuthUserSelector();
  const translateText = async () => {
    try {
      setIsLoading(true);
      const { data } = await getTranslation(text, authUser?.language || 'en');
      const { translatedText } = data?.translations[0];
      setTextTranslation(translatedText);
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const hideTranslation = () => setTextTranslation('');
  const isTranslationAvailable = !!textTranslation;

  if (!text) {
    return children;
  }

  return (
    <div className={styles['translation']}>
      <span>{children}</span>
      <Loader className={styles['loader']} isActive={isLoading} />
      {!isTranslationAvailable ? (
        <div className={cn(styles['translation-button'], 'mt-10')} onClick={translateText}>
          See translation
        </div>
      ) : (
        <>
          <div className={styles['text-translation']}>
            {textTranslation}
            <div className={styles['translated-by']}>&#8213; Translated by Google</div>
          </div>
          <div className={cn(styles['translation-button'], 'mt-10')} onClick={hideTranslation}>
            Hide translation
          </div>
        </>
      )}
    </div>
  );
};

export default Translation;
