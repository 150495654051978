import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
import { insertCommitmentToIndex, removeCommitmentFromIndex } from 'utils/loadHelper';
// types
import { Load, LoadCommitment, Plan } from 'types';

type ActionCommitmentPayload = {
  loadId: string;
  editLoadId?: string;
  commitmentIndex: number;
};

type AddEmptyCommitmentPayload = ActionCommitmentPayload & {
  commitment: LoadCommitment;
};

type PlanWorksheetState = {
  plan?: Omit<Plan, 'loadings'>;
  planLoads: Load[];
  isLoading: boolean;
};

const initialState: PlanWorksheetState = {
  isLoading: false,
  planLoads: [],
};

const planWorksheetSlice = createSlice({
  name: 'planWorksheet',
  initialState,
  reducers: {
    addEmptyCommitment(state, action: PayloadAction<AddEmptyCommitmentPayload>) {
      const { commitmentIndex, commitment, loadId, editLoadId } = action.payload;
      state.planLoads = state.planLoads.map(load => {
        if (load.id !== loadId) return load;
        if (editLoadId && loadId !== editLoadId) {
          return {
            ...load,
            edit_loading: load.edit_loading
              ? {
                  ...load.edit_loading,
                  commitments: insertCommitmentToIndex(
                    commitmentIndex,
                    commitment,
                    load.edit_loading.commitments
                  ),
                }
              : undefined,
          };
        }
        return {
          ...load,
          commitments: insertCommitmentToIndex(commitmentIndex, commitment, load.commitments),
        };
      });
    },
    removeEmptyCommitment(state, action: PayloadAction<ActionCommitmentPayload>) {
      const { commitmentIndex, loadId, editLoadId } = action.payload;
      state.planLoads = state.planLoads.map(load => {
        if (load.id !== loadId) return load;
        if (editLoadId && loadId !== editLoadId) {
          return {
            ...load,
            edit_loading: load.edit_loading
              ? {
                  ...load.edit_loading,
                  commitments: removeCommitmentFromIndex(
                    commitmentIndex,
                    load.edit_loading.commitments
                  ),
                }
              : undefined,
          };
        }
        return {
          ...load,
          commitments: removeCommitmentFromIndex(commitmentIndex, load.commitments),
        };
      });
    },
    replacePlanLoad(state, action: PayloadAction<{ replaceId: string; loadToReplace: Load }>) {
      const { replaceId, loadToReplace } = action.payload;
      state.planLoads = state.planLoads.map(load => (load.id === replaceId ? loadToReplace : load));
    },
    replacePlanLoads(
      state,
      action: PayloadAction<{
        replaceIds: string[];
        loadsToReplace: { replaceId: string; load: Load }[];
      }>
    ) {
      const { replaceIds, loadsToReplace } = action.payload;
      state.planLoads = state.planLoads.map(load =>
        replaceIds.includes(load.id)
          ? (loadsToReplace.find(({ replaceId }) => replaceId === load.id)?.load as Load)
          : load
      );
    },
    setPlan(state, action: PayloadAction<Plan>) {
      state.plan = action.payload;
      state.planLoads = action.payload.loadings;
      state.isLoading = false;
    },
    setPlanLoad(state, action: PayloadAction<Load>) {
      state.planLoads = state.planLoads.map(load =>
        load.id === action.payload.id ? action.payload : load
      );
    },
    setPlanEditLoad(state, action: PayloadAction<{ loadId: string; editLoad: Load }>) {
      const { loadId, editLoad } = action.payload;
      state.planLoads = state.planLoads.map(load =>
        load.id === loadId ? { ...load, edit_loading: editLoad } : load
      );
    },
    setPlanIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    removePlanLoad(state, action: PayloadAction<string>) {
      const loadId = action.payload;
      state.planLoads = state.planLoads.filter(({ id }) => id !== loadId);
    },
    resetPlanState() {
      return initialState;
    },
  },
});

export const {
  addEmptyCommitment,
  removeEmptyCommitment,
  replacePlanLoad,
  replacePlanLoads,
  setPlan,
  setPlanLoad,
  setPlanEditLoad,
  setPlanIsLoading,
  removePlanLoad,
  resetPlanState,
} = planWorksheetSlice.actions;

export default planWorksheetSlice.reducer;
