import React, { useState, Fragment, useCallback } from 'react';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import CutLevelSelect from './CutLevelSelect';
import DateTimeSubSection from './SubSections/DateTimeSubSection';
import EstimatedTravelTimeRow from 'components/EstimatedTravelTimeRow';
import GroupAssignmentSubSection from './SubSections/GroupAssignmentSubSection';
import FieldHighlighter from 'components/FieldHighlighter';
import FormInput from 'components/Input/FormInput';
import IconInput from 'components/Input/IconInput';
import NumberInput from 'components/Input/NumberInput';
import Location from 'components/Location';
import NoteCollapse from 'components/Collapse/NoteCollapse';
import TruncatedText from 'components/TruncatedText';
import WaypointWithLine from 'components/WaypointWithLine';
import HeadCountSubSection from './SubSections/HeadCountSubSection';
import CheckBox from 'components/CheckBox';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
// hooks
import useStateArray from 'hooks/useStateArray';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
// utils
import { calculateEstimatedTime, calculateSaleEstimatedTime } from './helper';
import debounce from 'lodash.debounce';
import {
  getCommitmentTimeToLoad,
  getHeadCountSumByCommitmentType,
  getLoadCommitmentAddressEntity,
  getUnMatchedSourceName,
  getUnMatchedDestinationName,
  isSaleLoad,
  isValidCommitmentDateRange,
  findSourceCommitment,
} from 'utils/loadHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// types
import { CommitmentsLogisticsSectionTypes } from './LoadFormSections.types';
import { CommitmentTypes, LoadCommitment } from 'types';
// styles
import styles from '../LoadMatchingForm.module.scss';

function formatCommitments(commitments: LoadCommitment[]): LoadCommitment[] {
  return commitments.map(cm => ({ ...cm, minutes_for_load: getCommitmentTimeToLoad(cm) }));
}

const CommitmentsLogisticsSection = ({
  load,
  updateCommitment,
  allowUpdateAllLoads = false,
  allowUpdateFirstOrigins = false,
}: CommitmentsLogisticsSectionTypes) => {
  const [stateCommitments, setCommitments, { setValuesById }] = useStateArray<LoadCommitment>(
    formatCommitments(load.commitments)
  );
  const [applyToOtherLoads, setApplyToOtherLoads] = useState<Record<string, boolean>>({});
  const { formatMessage } = useIntl();
  const isSale = isSaleLoad(load);
  const { sourcesHeadCount, destHeadCount } = getHeadCountSumByCommitmentType(stateCommitments);

  useDidUpdateEffect(() => {
    setCommitments(formatCommitments(load.commitments));
  }, [setCommitments, load.commitments]);

  const handleUpdateCommitment = useCallback(debounce(updateCommitment, 300), [load.id]);

  const handleUpdateCommitmentNotes = useCallback(
    debounce(
      (commitmentId: string, comment: string) =>
        updateCommitment(commitmentId, { comment }).catch(toastResponseErrors),
      1000
    ),
    [load.id]
  );

  const handleChangeDateTime = (
    commitmentId: string,
    dateTimeValue: string,
    applyToOtherLoads?: boolean
  ) => {
    setValuesById(commitmentId, { arrive_at: dateTimeValue });
    handleUpdateCommitment(commitmentId, { arrive_at: dateTimeValue }, applyToOtherLoads || false);
  };

  const handleChangeHeadCount = (commitmentId: string, value?: number) => {
    setValuesById(commitmentId, { head_count: value });
    handleUpdateCommitment(commitmentId, { head_count: value });
  };

  const handleChangeCutLevel = (commitmentId: string, value: string) => {
    setValuesById(commitmentId, { cut_level: +value });
    handleUpdateCommitment(commitmentId, { cut_level: +value });
  };

  const handleToggleCheckBox = (commitmentId: string, arriveAt: string | null) => {
    const currentApplyToOtherLoads = applyToOtherLoads[commitmentId] || false;
    setApplyToOtherLoads(prevState => ({
      ...prevState,
      [commitmentId]: !currentApplyToOtherLoads,
    }));

    if (!currentApplyToOtherLoads && arriveAt) {
      handleChangeDateTime(commitmentId, arriveAt, true);
    }
  };

  return (
    <Fragment>
      {stateCommitments.map((stateCommitment, index) => {
        const {
          arrive_at,
          barn_id,
          comment,
          commitment_type,
          cut_level,
          farm,
          head_count,
          id,
          matched,
          minutes_for_load,
          pig_group,
          pig_group_assign_name,
          pig_group_assign_type,
          position,
          travel_distance,
          travel_seconds,
        } = stateCommitment;
        const entity = getLoadCommitmentAddressEntity(stateCommitment);
        const isDestination = commitment_type === CommitmentTypes.destination;
        const hasCutLevel = isSale && !isDestination;
        const isFirst = !index;
        const isLast = !stateCommitments[index + 1];
        const isValidDate = isValidCommitmentDateRange(
          stateCommitment,
          stateCommitments[index - 1]
        );
        const headCountDiff = isDestination
          ? sourcesHeadCount - destHeadCount
          : destHeadCount - sourcesHeadCount;
        const commitmentName =
          entity?.name ||
          (isDestination
            ? getUnMatchedDestinationName(
                position,
                stateCommitments.filter(findSourceCommitment).length,
                formatMessage
              )
            : getUnMatchedSourceName(position, formatMessage));
        const hasUpdateAllLoadsCheckBox =
          allowUpdateAllLoads || (allowUpdateFirstOrigins && isFirst);

        return (
          <WaypointWithLine type={commitment_type} isEnd={isLast} key={id}>
            <div className={styles['commitment-timeline']}>
              <div className="mb-15">
                <div className={styles.name}>
                  <TruncatedText text={commitmentName} maxWidth={300} size="small" hasTooltip />
                </div>
                {matched && (
                  <div className={styles.label}>
                    <Location state={entity?.state} city={entity?.city} />
                  </div>
                )}
              </div>
              {matched && (
                <Fragment>
                  {!isLast && (
                    <FormInput
                      label={<FormattedMessage id="general.timeToLoad" />}
                      inputRenderer={() => (
                        <NumberInput
                          max={999999}
                          onChange={value => {
                            setValuesById(id, { minutes_for_load: value });
                            handleUpdateCommitment(id, { minutes_for_load: value || 0 });
                          }}
                          value={minutes_for_load ?? undefined}
                        >
                          {props => (
                            <IconInput
                              placeholder="0"
                              hasNoBorders
                              icon={<ClockIcon className={styles['icon']} />}
                              {...props}
                            />
                          )}
                        </NumberInput>
                      )}
                    />
                  )}
                  <FieldHighlighter leftIndent={65} hasValue={isValidDate}>
                    <DateTimeSubSection
                      // next commitment arrive time depends on previous one
                      estimatedTime={(isSale ? calculateSaleEstimatedTime : calculateEstimatedTime)(
                        index,
                        stateCommitments
                      )}
                      timeType={isDestination ? 'ArrivalTime' : 'PickUpTime'}
                      label={
                        <FormattedMessage
                          id={`load.${isDestination ? 'arrivalDateTime' : 'pickUpDateTime'}`}
                        />
                      }
                      pickerId={`datetime-${id}`}
                      dateTimeValue={arrive_at || ''}
                      onChange={value => {
                        handleChangeDateTime(id, value, applyToOtherLoads[id]);
                      }}
                    />
                    {hasUpdateAllLoadsCheckBox && (
                      <CheckBox
                        className="mt-25 mb-10"
                        isChecked={applyToOtherLoads[stateCommitment.id] || false}
                        onClick={() =>
                          handleToggleCheckBox(stateCommitment.id, stateCommitment.arrive_at)
                        }
                        label={<FormattedMessage id="general.applyDateToOtherLoads" />}
                      />
                    )}
                  </FieldHighlighter>
                  {hasCutLevel && (
                    <FieldHighlighter leftIndent={65} hasValue={!!cut_level}>
                      <CutLevelSelect
                        value={(cut_level || '').toString()}
                        onChange={value => handleChangeCutLevel(id, value)}
                      />
                    </FieldHighlighter>
                  )}
                  <FieldHighlighter leftIndent={65} hasValue={!!head_count}>
                    <HeadCountSubSection
                      commitment={stateCommitment}
                      headCountDiff={headCountDiff}
                      onChange={value => handleChangeHeadCount(id, value)}
                    />
                  </FieldHighlighter>

                  {!isSale && isDestination && (
                    <GroupAssignmentSubSection
                      farmType={farm?.farm_type}
                      barnId={barn_id}
                      assignType={pig_group_assign_type || 'skip'}
                      customGroupName={pig_group_assign_name || ''}
                      existingGroup={pig_group}
                      onChange={values => {
                        setValuesById(id, values);
                        if (
                          values.pig_group_assign_type === 'skip' ||
                          values.pig_group_assign_type === 'generate'
                        ) {
                          handleUpdateCommitment(id, {
                            pig_group_assign_type: values.pig_group_assign_type,
                            pig_group_assign_name: '',
                            pig_group_id: null,
                          });
                        }
                        if (
                          (pig_group_assign_type === 'existing' && values.pig_group) ||
                          (pig_group_assign_type === 'custom_name' && values.pig_group_assign_name)
                        ) {
                          const pigGroupId = values.pig_group?.id
                            ? +values.pig_group?.id
                            : undefined;
                          handleUpdateCommitment(id, {
                            pig_group_assign_type: pig_group_assign_type,
                            pig_group_assign_name: values.pig_group_assign_name || '',
                            pig_group_id: pig_group_assign_type === 'existing' ? pigGroupId : null,
                          });
                        }
                      }}
                    />
                  )}

                  <NoteCollapse
                    isOpened={Boolean(comment)}
                    label={<FormattedMessage id="note.addLoadNote" />}
                    className="mv-15"
                    value={comment || ''}
                    onChange={updComment => {
                      setValuesById(id, { comment: updComment });
                      handleUpdateCommitmentNotes(id, updComment);
                    }}
                  />

                  {farm?.caretaker_availability && (
                    <div className="mb-10">
                      <span className="bold">
                        <FormattedMessage id="general.caregiverAvailability" />
                      </span>
                      <div className="pre-text mt-5">{farm.caretaker_availability}</div>
                    </div>
                  )}
                </Fragment>
              )}
              {!isLast && (
                <EstimatedTravelTimeRow distance={travel_distance} seconds={travel_seconds} />
              )}
            </div>
          </WaypointWithLine>
        );
      })}
    </Fragment>
  );
};

export default CommitmentsLogisticsSection;
