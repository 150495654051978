import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// hooks
import useMeasurementSystem from 'hooks/useMeasurementSystem';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from '../weightHelper';

export default function useWeightHelper() {
  const { measurementSystem, weightMeasureKey } = useMeasurementSystem();

  const getWeightRangeWithLabel = (minValue: number | null, maxValue: number | null) => (
    <FormattedMessage id={`general.${weightMeasureKey}`}>
      {weightValue => `${minValue || 0} - ${maxValue || 0} ${weightValue}`}
    </FormattedMessage>
  );

  const getWeightWithLabel = (value: number | null) => (
    <FormattedMessage id={`general.${weightMeasureKey}`}>
      {weightValue => `${value || 0} ${weightValue}`}
    </FormattedMessage>
  );

  return {
    formatWeightToDisplay: (value: number | null): number | null =>
      value !== null ? formatBackEndWeightToSystem(value, measurementSystem) : null,
    formatWeightToSave: (value: number | null): number | null =>
      value !== null ? formatWeightToBackEndFormat(value, measurementSystem) : null,
    getWeightWithLabel,
    getWeightRangeWithLabel,
    weightLabel: <FormattedMessage id={`general.${weightMeasureKey}`} />,
  };
}
