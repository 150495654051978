import React from 'react';
// components
import FormInput from 'components/Input/FormInput';
import IconInput from 'components/Input/IconInput';
import { FormattedMessage } from 'react-intl';
import NumberInput from 'components/Input/NumberInput';
import { ReactComponent as PiggyIcon } from 'icons/piggy.svg';
import SuggestionBox from 'components/SuggestionBox';
// utils
import { bold } from 'utils';
// types
import { LoadCommitment } from 'types';
// styles
import styles from '../../LoadMatchingForm.module.scss';

type HeadCountSubSectionProps = {
  isDisabled?: boolean;
  onChange: (value?: number) => void;
  onBlur?: () => void;
  commitment: LoadCommitment;
  headCountDiff: number;
};

const HeadCountSubSection = ({
  isDisabled = false,
  onChange,
  commitment,
  headCountDiff,
  onBlur,
}: HeadCountSubSectionProps) => {
  const headCount = commitment.head_count || '';

  return (
    <div>
      <FormInput
        label={<FormattedMessage id="general.targetHeadCount" />}
        inputRenderer={() => (
          <NumberInput
            disabled={isDisabled}
            value={headCount === '' ? undefined : headCount}
            onChange={onChange}
          >
            {props => (
              <IconInput
                hasNoBorders
                icon={<PiggyIcon className={styles['icon']} />}
                placeholder="0"
                onBlur={onBlur}
                {...props}
              />
            )}
          </NumberInput>
        )}
      />
      {headCountDiff > 0 && (
        <SuggestionBox
          isDisabled={isDisabled}
          suggestion={
            <FormattedMessage
              id="load.unallocatedHeads"
              values={{ b: bold, value: headCountDiff }}
            />
          }
          buttonLabel={<FormattedMessage id="general.yes" />}
          onAccept={() => onChange(+headCount + headCountDiff)}
        />
      )}
    </div>
  );
};

export default HeadCountSubSection;
