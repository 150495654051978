import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import { ReactComponent as FarmIcon } from 'icons/farm.svg';
import { ReactComponent as GroupsIcon } from 'icons/pig-groups.svg';
import { ReactComponent as PiggyIcon } from 'icons/piggy.svg';
import { ReactComponent as WeightIcon } from 'icons/weight.svg';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as DocIcon } from 'icons/ep-doc.svg';
import { ReactComponent as GeneticIcon } from 'icons/ep-dna.svg';
import { ReactComponent as IndustryIcon } from 'icons/industry.svg';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';
import { ReactComponent as TreatmentIcon } from 'icons/treatment.svg';
import { ReactComponent as BusinessIcon } from 'icons/business-socks.svg';
import { ReactComponent as MarkerIcon } from 'icons/map-marker.svg';
import { ReactComponent as ScissorsIcon } from 'icons/scissors.svg';
import { ReactComponent as CheckCircleIcon } from 'icons/check-circle.svg';
// utils
import { isEqualFields } from 'utils';
import { formatSectionOptionsValues } from 'utils/userFilterHelper';
// types
import { ListOptionItem } from './Filters.types';
import {
  FilterSectionsGetter,
  GroupName,
  GroupNames,
  LiveUserFilter,
  SearchedSections,
  SectionNames,
  UserFilterSectionCondition,
  UserFilterSectionOptions,
  UserFilterSectionType,
} from 'types';
import { UserFilterSection, StateUserFilterSection } from 'types/filterSections';
// constants
import { relativeDatesConditions } from 'utils/constants/userFilter';

const getOptionLabel = (filterGroupName: GroupName, valueType: 'allFilters' | 'anyFilters') => {
  switch (filterGroupName) {
    case GroupNames.LogisticsPigGroups:
    case GroupNames.LogisticsSpecialRequirements:
    case GroupNames.LogisticsSalesPigGroups:
      return `filters.groups.${valueType}`;
    case GroupNames.TruckingsLoadings:
    case GroupNames.LogisticsLoadings:
    case GroupNames.LogisticsContractCommitments:
      return `filters.loads.${valueType}`;
    case GroupNames.LogisticsSalesResults:
      return `filters.salesResults.${valueType}`;
    case GroupNames.LogisticsBarns:
      return `filters.barns.${valueType}`;
    default:
      return '';
  }
};

export const getAllOrAnyOptions = (type: GroupName): ListOptionItem<'all' | 'any'>[] => [
  { value: 'all', label: <FormattedMessage id={getOptionLabel(type, 'allFilters')} /> },
  { value: 'any', label: <FormattedMessage id={getOptionLabel(type, 'anyFilters')} /> },
];

export const getSpecialRequirementsSections: FilterSectionsGetter = formatMessage => [
  {
    value: SectionNames.PigGroup,
    label: formatMessage({ id: 'general.groupId' }),
    icon: GroupsIcon,
  },
  {
    value: SectionNames.PigsCount,
    label: formatMessage({ id: 'general.inventory' }),
    icon: PiggyIcon,
  },
  {
    value: SectionNames.Weight,
    label: formatMessage({ id: 'general.estAvgWeight' }),
    icon: WeightIcon,
  },
  {
    value: SectionNames.PigGroupGenetics,
    label: formatMessage({ id: 'general.genetics' }),
    icon: GeneticIcon,
  },
  {
    value: SectionNames.PigGroupTreatmentProducts,
    label: formatMessage({ id: 'general.treatments' }),
    icon: TreatmentIcon,
  },
  {
    value: SectionNames.LooseSowHoused,
    label: formatMessage({ id: 'general.looseSowHoused' }),
    icon: FarmIcon,
  },
];

export const getSalesResultsSections: FilterSectionsGetter = formatMessage => [
  {
    value: SectionNames.Loading,
    label: formatMessage({ id: 'general.loadName' }),
    icon: TruckIcon,
  },
  {
    value: SectionNames.HeadCount,
    label: formatMessage({ id: 'general.salesResultHeadCount' }),
    icon: PiggyIcon,
  },
  {
    value: SectionNames.ContractCommitmentHeadCount,
    label: formatMessage({ id: 'general.contractHeadCount' }),
    icon: PiggyIcon,
  },
  {
    value: SectionNames.Contract,
    label: formatMessage({ id: 'general.contractName' }),
    icon: DocIcon,
  },
  {
    value: SectionNames.Farm,
    label: formatMessage({ id: 'general.farmName' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.PigGroup,
    label: formatMessage({ id: 'general.groupName' }),
    icon: GroupsIcon,
  },
  {
    value: SectionNames.DeliveredAt,
    label: formatMessage({ id: 'general.deliveryDate' }),
    icon: CalendarIcon,
  },
  {
    value: SectionNames.CutLevel,
    label: formatMessage({ id: 'general.cutLevel' }),
    icon: ScissorsIcon,
  },
  {
    value: SectionNames.DeadOnArrival,
    label: formatMessage({ id: 'general.deadOnArrival' }),
    icon: PiggyIcon,
  },
  {
    value: SectionNames.PackingPlant,
    label: formatMessage({ id: 'general.packingPlant' }),
    icon: IndustryIcon,
  },
  {
    value: SectionNames.TruckingCompany,
    label: formatMessage({ id: 'general.truckingCompany' }),
    icon: TruckIcon,
  },
];

const defaultGroupSections: FilterSectionsGetter = formatMessage => [
  {
    value: SectionNames.Farm,
    label: formatMessage({ id: 'general.farmName' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.PigGroup,
    label: formatMessage({ id: 'general.groupId' }),
    icon: GroupsIcon,
  },
  {
    value: SectionNames.PigsCount,
    label: formatMessage({ id: 'general.inventory' }),
    icon: PiggyIcon,
  },
  {
    value: SectionNames.Weight,
    label: formatMessage({ id: 'general.estAvgWeight' }),
    icon: WeightIcon,
  },
];

export const getGroupsSections: FilterSectionsGetter = formatMessage => [
  ...defaultGroupSections(formatMessage),
  {
    value: SectionNames.FarmType,
    label: formatMessage({ id: 'general.farmType' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.ProjectedInventory,
    label: formatMessage({ id: 'general.projectedInventory' }),
    icon: PiggyIcon,
  },
  {
    value: SectionNames.LooseSowHoused,
    label: formatMessage({ id: 'general.looseSowHoused' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.ExternalSource,
    label: formatMessage({ id: 'general.externalSource' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.PigGroupGenetics,
    label: formatMessage({ id: 'general.genetics' }),
    icon: GeneticIcon,
  },
];

export const getWorksheetGroupsSections: FilterSectionsGetter = formatMessage => [
  ...defaultGroupSections(formatMessage),
  {
    value: SectionNames.FirstSaleDate,
    label: formatMessage({ id: 'general.earliestSaleDate' }),
    icon: CalendarIcon,
  },
  {
    value: SectionNames.CutLevel,
    label: formatMessage({ id: 'general.latestCut' }),
    icon: ScissorsIcon,
  },
  {
    value: SectionNames.LastSaleDate,
    label: formatMessage({ id: 'general.latestSaleDate' }),
    icon: CalendarIcon,
  },
  {
    value: SectionNames.LastPackingPlant,
    label: formatMessage({ id: 'general.latestPlant' }),
    icon: IndustryIcon,
  },
  {
    value: SectionNames.EstimatedDaysOnFeed,
    label: formatMessage({ id: 'general.daysOnFeed' }),
    icon: CalendarIcon,
  },
];

export const getBarnsSections: FilterSectionsGetter = formatMessage => [
  {
    value: SectionNames.Barn,
    label: formatMessage({ id: 'general.barnName' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.Farm,
    label: formatMessage({ id: 'general.farmName' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.FarmState,
    label: formatMessage({ id: 'general.farmState' }),
    icon: MarkerIcon,
  },
  {
    value: SectionNames.Capacity,
    label: formatMessage({ id: 'general.capacity' }),
    icon: PiggyIcon,
  },
  {
    value: SectionNames.ScheduleToEmptyDate,
    label: formatMessage({ id: 'general.scheduledToEmptyDate' }),
    icon: CalendarIcon,
  },
];

export const getScheduledListSections: FilterSectionsGetter = formatMessage => [
  {
    value: SectionNames.ContractCommitment,
    label: formatMessage({ id: 'general.name' }),
    icon: TruckIcon,
  },
  {
    value: SectionNames.ContractDate,
    label: formatMessage({ id: 'load.loadDate' }),
    icon: CalendarIcon,
  },
  {
    value: SectionNames.HeadCount,
    label: formatMessage({ id: 'general.headCount' }),
    icon: PiggyIcon,
  },
  {
    value: SectionNames.Weight,
    label: formatMessage({ id: 'general.targetWeightRange' }),
    icon: WeightIcon,
  },
  {
    value: SectionNames.Contract,
    label: formatMessage({ id: 'general.contractName' }),
    icon: DocIcon,
  },
  {
    value: SectionNames.ContractOwnerCompany,
    label: formatMessage({ id: 'general.contractOwner' }),
    icon: BusinessIcon,
  },
];

export const getLoadingsSections: FilterSectionsGetter = formatMessage => [
  {
    value: SectionNames.Loading,
    label: formatMessage({ id: 'general.loadName' }),
    icon: TruckIcon,
  },
  {
    value: SectionNames.LoadType,
    label: formatMessage({ id: 'general.loadType' }),
    icon: TruckIcon,
  },
  {
    value: SectionNames.Status,
    label: formatMessage({ id: 'general.loadStatus' }),
    icon: CheckCircleIcon,
  },
  {
    value: SectionNames.BaseDate,
    label: formatMessage({ id: 'general.date' }),
    icon: CalendarIcon,
  },
  {
    value: SectionNames.TruckingCompany,
    label: formatMessage({ id: 'general.truckingCompany' }),
    icon: TruckIcon,
  },
  {
    value: SectionNames.ContractNames,
    label: formatMessage({ id: 'general.contract' }),
    icon: DocIcon,
  },
  {
    value: SectionNames.SourceEntityNames,
    label: formatMessage({ id: 'loadsTable.origin' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.SourcePigGroupNames,
    label: formatMessage({ id: 'loadsTable.originGroup' }),
    icon: GroupsIcon,
  },
  {
    value: SectionNames.DestinationEntityNames,
    label: formatMessage({ id: 'loadsTable.destination' }),
    icon: FarmIcon,
  },
  {
    value: SectionNames.DestinationPigGroupNames,
    label: formatMessage({ id: 'loadsTable.destinationGroup' }),
    icon: GroupsIcon,
  },
  {
    value: SectionNames.PigGroupEstimatedWeight,
    label: formatMessage({ id: 'load.avgWeight' }),
    icon: WeightIcon,
  },
  {
    value: SectionNames.SalesResultAvgPigWeight,
    label: formatMessage({ id: 'loadsTable.prelimWeight' }),
    icon: WeightIcon,
  },
];

export const getTruckingLoadingsSections: FilterSectionsGetter = formatMessage => [
  {
    value: SectionNames.Loading,
    label: formatMessage({ id: 'general.loadName' }),
    icon: TruckIcon,
  },
  {
    value: SectionNames.TruckingCompanyStatus,
    label: formatMessage({ id: 'general.loadStatus' }),
    icon: CheckCircleIcon,
  },
  {
    value: SectionNames.LastArriveAt,
    label: formatMessage({ id: 'general.date' }),
    icon: CalendarIcon,
  },
];

export function isInvalidSectionData(
  filter: StateUserFilterSection,
  condition: UserFilterSectionCondition,
  options: UserFilterSectionOptions
) {
  if (!condition) return true;

  const optionsValues = formatSectionOptionsValues(options);

  switch (filter.type) {
    case SectionNames.LoadType:
      return condition === 'any' ? false : !optionsValues.load_type;
    case SectionNames.CutLevel:
      return ['any', 'none'].includes(condition) ? false : !optionsValues.cut_level;
    case SectionNames.TruckingCompanyStatus:
    case SectionNames.Status:
      return condition === 'any' ? false : !optionsValues.status;
    case SectionNames.PigGroupGenetics:
      return !optionsValues.genetic_ids?.length;
    case SectionNames.PigGroupTreatmentProducts:
      return !optionsValues.treatment_product_ids?.length;
    case SectionNames.FarmState:
      return condition !== 'any' ? !optionsValues.text : false;
    case SectionNames.Barn:
    case SectionNames.Farm:
    case SectionNames.ContractOwnerCompany:
    case SectionNames.DestinationEntityNames:
    case SectionNames.DestinationPigGroupNames:
    case SectionNames.SourceEntityNames:
    case SectionNames.SourcePigGroupNames:
    case SectionNames.PigGroup:
    case SectionNames.Loading:
    case SectionNames.Contract:
    case SectionNames.ContractNames:
    case SectionNames.PackingPlant:
    case SectionNames.LastPackingPlant:
    case SectionNames.TruckingCompany:
    case SectionNames.ExternalSource:
      return !optionsValues.text;
    case SectionNames.Capacity:
    case SectionNames.ContractCommitmentHeadCount:
    case SectionNames.EstimatedDaysOnFeed:
    case SectionNames.PigsCount:
    case SectionNames.HeadCount:
    case SectionNames.DeadOnArrival:
    case SectionNames.Weight: {
      const isEmptyRangeValues = !optionsValues.count_from && !optionsValues.count_to;
      const isEmptyRange = condition === 'range' && isEmptyRangeValues;
      const isEmptyCount = condition !== 'range' && !optionsValues.count;
      return isEmptyRange || isEmptyCount;
    }
    case SectionNames.ArriveAt:
    case SectionNames.ContractDate:
    case SectionNames.ScheduleToEmptyDate:
    case SectionNames.FirstSaleDate:
    case SectionNames.LastSaleDate:
    case SectionNames.DeliveredAt:
    case SectionNames.BaseDate: {
      const isEmptyDays = relativeDatesConditions.includes(condition) && !optionsValues.days;
      const isEmptyDate = ['gt', 'eq', 'lt'].includes(condition) && !optionsValues.date;
      const isEmptyRange =
        condition === 'range' && !optionsValues.date_start && !optionsValues.date_end;
      return isEmptyDate || isEmptyDays || isEmptyRange;
    }
    default:
      return false;
  }
}

export function isSectionsEqual(
  nextSection: StateUserFilterSection,
  prevSection?: UserFilterSection
) {
  if (
    !prevSection ||
    prevSection.type !== nextSection.type ||
    prevSection.condition !== nextSection.condition
  )
    return false;
  return isEqualFields(prevSection.options?.[0] || {}, nextSection.options?.[0] || {}, [
    'key',
    'value',
  ]);
}

export function filterByTypeAndCondition(
  section: StateUserFilterSection,
  type: UserFilterSectionType,
  condition: UserFilterSectionCondition
) {
  return section.type === type && section.condition === condition;
}

export function getSearchedFilters(
  sectionNames: SearchedSections,
  search: string
): LiveUserFilter[] {
  return [
    {
      any_condition: true,
      sections: sectionNames.map(sectionType => ({
        type: sectionType,
        condition: 'cont',
        options: [{ key: 'text', value: search.toString().trim() }],
      })),
    },
  ];
}
