import { FC, SVGProps } from 'react';
import { InitialFilter } from 'components/Filters/Filters.types';
import { FormatMessageFunc } from './common';
import {
  StateUserFilterSection,
  UserFilterSection,
  ExistingUserFilterSection,
} from './filterSections';

export enum PageNames {
  arrivalBarns = 'arrivalBarns',
  arrivalPlans = 'arrivalPlans',
  contracts = 'contracts',
  loads = 'loads',
  loadArchive = 'loadArchive',
  loadsPreview = 'loadsPreview',
  salePlans = 'salePlans',
  salesResults = 'salesResults',
  specialRequirements = 'specialRequirements',
  transferBarns = 'transferBarns',
  transferPlans = 'transferPlans',
  worksheetGroups = 'worksheetGroups',
  truckingLoads = 'truckingLoads',
  loadResults = 'loadResults',
}

export enum GroupNames {
  LogisticsPigGroups = 'LogisticsPigGroups',
  LogisticsBarns = 'LogisticsBarns',
  LogisticsContractCommitments = 'LogisticsContractCommitments',
  LogisticsLoadings = 'LogisticsLoadings',
  LogisticsSpecialRequirements = 'LogisticsSpecialRequirements',
  LogisticsSalesResults = 'LogisticsSalesResults',
  LogisticsSalesPigGroups = 'LogisticsSalesPigGroups',
  TruckingsLoadings = 'TruckingsLoadings',
}

export enum SectionNames {
  ArriveAt = 'ArriveAt',
  BaseDate = 'BaseDate',
  Barn = 'Barn',
  Capacity = 'Capacity',
  Contract = 'Contract',
  ContractNames = 'ContractNames',
  CutLevel = 'CutLevel',
  ContractCommitment = 'ContractCommitment',
  ContractCommitmentHeadCount = 'ContractCommitmentHeadCount',
  ContractDate = 'ContractDate',
  ContractOwnerCompany = 'ContractOwnerCompany',
  DestinationEntityNames = 'DestinationEntityNames',
  DestinationPigGroupNames = 'DestinationPigGroupNames',
  DeliveredAt = 'DeliveredAt',
  EstimatedDaysOnFeed = 'EstimatedDaysOnFeed',
  Farm = 'Farm',
  FarmState = 'FarmState',
  FarmType = 'FarmType',
  FirstSaleDate = 'FirstSaleDate',
  HeadCount = 'HeadCount',
  LastPackingPlant = 'LastPackingPlant',
  LastSaleDate = 'LastSaleDate',
  Loading = 'Loading',
  LoadType = 'LoadType',
  LooseSowHoused = 'LooseSowHoused',
  SourceEntityNames = 'SourceEntityNames',
  SourcePigGroupNames = 'SourcePigGroupNames',
  Status = 'Status',
  PackingPlant = 'PackingPlant',
  PigsCount = 'PigsCount',
  PigGroup = 'PigGroup',
  PigGroupGenetics = 'PigGroupGenetics',
  PigGroupTreatmentProducts = 'PigGroupTreatmentProducts',
  PigGroupEstimatedWeight = 'PigGroupEstimatedWeight',
  SalesResultAvgPigWeight = 'SalesResultAvgPigWeight',
  ScheduleToEmptyDate = 'ScheduleToEmptyDate',
  TruckingCompany = 'TruckingCompany',
  Weight = 'Weight',
  ProjectedInventory = 'ProjectedInventory',
  ExternalSource = 'ExternalSource',
  LastArriveAt = 'LastArriveAt',
  TruckingCompanyStatus = 'TruckingCompanyStatus',
  DeadOnArrival = 'DeadOnArrival',
}

export type GroupName = keyof typeof GroupNames;

export type PageName = keyof typeof PageNames;

export type UserFilterSectionCondition = UserFilterSection['condition'];
export type UserFilterSectionType = UserFilterSection['type'];
export type UserFilterSectionOptions = UserFilterSection['options'];

export type ConditionOptionItem = {
  value: UserFilterSectionCondition;
  label: JSX.Element;
  title?: JSX.Element;
};

export type SearchedSections = (
  | SectionNames.Contract
  | SectionNames.Farm
  | SectionNames.Loading
  | SectionNames.PackingPlant
  | SectionNames.TruckingCompany
)[];

export type LiveUserFilter = {
  any_condition: boolean;
  sections: UserFilterSection[];
};

export type StateUserFilter = {
  id?: number;
  name?: string;
  any_condition: boolean;
  sections: StateUserFilterSection[];
};

export type ExistingUserFilter = {
  id: number;
  name: string;
  any_condition: boolean;
  group_name: GroupName;
  sections: ExistingUserFilterSection[];
};

// Redux: filters reducer

export type ReduxUserFilter = StateUserFilter & {
  savedUserFilters: {
    isLoading: boolean;
    isLoaded: boolean;
    resources: ExistingUserFilter[];
  };
  searchValue: string;
};

export type InitialFiltersState = {
  [key in PageNames]: ReduxUserFilter;
};

export type Page = { page: PageName };

export type UpdateSectionPayload = Page & {
  sectionIndex: number;
  condition: UserFilterSectionCondition;
  options?: UserFilterSectionOptions;
  other?: Record<string, never>;
};

export type AddSectionPayload = Page & { sectionType: UserFilterSectionType };

export type InitialFilterPayload = Page & { filter: InitialFilter };

export type SelectExistingFilterPayload = Page & { filter: ExistingUserFilter };

export type SetMobileFilterPayload = Page & { filter: StateUserFilter };

export type SetConditionPayload = Page & { any_condition: boolean };

export type SetSearchPayload = Page & { text: string };

export type SetSectionsPayload = Page & { sections: StateUserFilterSection[] };

export type MergeSectionPayload = Page & { section: UserFilterSection; isUnique?: boolean };

export type RemoveSectionPayload = Page & { sectionIndex: number };

export type FilterSectionsGetter = (
  formatMessage: FormatMessageFunc
) => {
  value: UserFilterSectionType;
  label: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}[];
