import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import Tooltip from 'components/Tooltip';
// utils
import cn from 'classnames';
// styles
import styles from './StatsBlocks.module.scss';

type StatsBlocksProps = {
  className?: string;
  options: {
    label: string | JSX.Element;
    value?: string | number | JSX.Element;
    tooltipLabel?: string | JSX.Element;
  }[];
};

const StatsBlocks = ({ options, className }: StatsBlocksProps) => (
  <div className={cn(styles['stats-body'], className)}>
    {options.map(({ label, value, tooltipLabel }, idx) => {
      const optionContent = (
        <div key={idx} className={cn(styles.block)}>
          <div className={styles.value}>
            {value || <FormattedMessage id="general.notAvailableAbbr" />}
          </div>
          <div className={styles.label}>{label}</div>
        </div>
      );

      return tooltipLabel ? (
        <Tooltip key={idx} trigger={optionContent} tooltipContent={tooltipLabel} />
      ) : (
        optionContent
      );
    })}
  </div>
);

export default StatsBlocks;
