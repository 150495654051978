// api
import {
  createSalesResults,
  declineLoad as decline,
  forceAcceptByTrucking,
  forceAcceptCommitment,
  getLoad,
  getLoadHistory,
  lockLogisticsSaleResult,
  moveLoadToArchive,
  moveLoadFromArchive,
  updateLoad,
  updateSalesResults,
  unlockLogisticsSaleResult,
  unMatchLoad,
  resetLoad,
} from 'requests/loads';
import { updateLoadCommitment } from 'requests/commitments';
// actions
import {
  mergeLoad,
  setLoad,
  setLoadLogs,
  setLoadIsLoading,
  setLoadLogsIsLoading,
} from '../reducers/load';
// utils
import { findDestinationCommitment, findSourceCommitment } from 'utils/loadHelper';
import { buildPreFilledSalesResults } from 'utils/salesResultsHelper';
// types
import { AppDispatch } from '../reduxStore';
import { Load } from 'types';
import {
  LoadCommitmentUpdateAttrs,
  LoadUpdateAttrs,
  SalesResultUpdateAttrs,
  SalesResultCreateAttrs,
} from 'types/handlers';

function loadPromise(func: Promise<Load>, dispatch: AppDispatch) {
  dispatch(setLoadIsLoading(true));
  return func
    .then(load => {
      dispatch(setLoad(load));
      dispatch(setLoadLogsIsLoading(true));
      return getLoadHistory(load.id).then(logs => {
        dispatch(setLoadLogs(logs));
        return load;
      });
    })
    .catch(err => {
      dispatch(setLoadIsLoading(false));
      dispatch(setLoadLogsIsLoading(false));
      throw err;
    });
}

export const fetchLoadWithLogs = (loadId: string) => (dispatch: AppDispatch) => {
  return loadPromise(getLoad(loadId), dispatch);
};

export const unMatchLoadData = (id: string) => (dispatch: AppDispatch) => {
  return loadPromise(unMatchLoad(id), dispatch);
};

export const resetLoadData = (id: string) => (dispatch: AppDispatch) => {
  return loadPromise(resetLoad(id), dispatch);
};

export const declineLoad = (id: string) => (dispatch: AppDispatch) => {
  return loadPromise(decline(id), dispatch);
};

export const makeLoadAsArchived = (id: string) => (dispatch: AppDispatch) => {
  return loadPromise(moveLoadToArchive(id), dispatch);
};

export const makeLoadAsUnArchived = (id: string) => (dispatch: AppDispatch) => {
  return loadPromise(moveLoadFromArchive(id), dispatch);
};

export const acceptByTrucking = (id: string) => (dispatch: AppDispatch) => {
  return loadPromise(forceAcceptByTrucking(id), dispatch);
};

export const acceptCommitment = (loadId: string, commitmentId: string) => (
  dispatch: AppDispatch
) => {
  return loadPromise(forceAcceptCommitment(loadId, commitmentId), dispatch);
};

export const updateCommitment = (
  loadId: string,
  commitmentId: string,
  attributes: LoadCommitmentUpdateAttrs
) => (dispatch: AppDispatch) => {
  return loadPromise(updateLoadCommitment(loadId, commitmentId, attributes), dispatch);
};

export const updateLoadData = (loadId: string, attrs: LoadUpdateAttrs) => (
  dispatch: AppDispatch
) => {
  return loadPromise(updateLoad(loadId, attrs), dispatch);
};

export const createLoadSalesResults = (loadId: string, attrs: SalesResultCreateAttrs[]) => (
  dispatch: AppDispatch
) => {
  dispatch(setLoadIsLoading(true));
  return createSalesResults(loadId, attrs)
    .then(load => {
      dispatch(setLoad(load));
      return load;
    })
    .catch(err => {
      dispatch(setLoadIsLoading(false));
      throw err;
    });
};

export const updateLoadSalesResults = (loadId: string, attrs: SalesResultUpdateAttrs[]) => (
  dispatch: AppDispatch
) => {
  dispatch(setLoadIsLoading(true));
  return updateSalesResults(loadId, attrs)
    .then(load => {
      dispatch(setLoad(load));
      return load;
    })
    .catch(err => {
      dispatch(setLoadIsLoading(false));
      throw err;
    });
};

export const addPreFilledSalesResult = (load: Load) => (dispatch: AppDispatch) => {
  const salesResults = buildPreFilledSalesResults(
    load.commitments.filter(findSourceCommitment),
    load.commitments.filter(findDestinationCommitment)
  );
  dispatch(mergeLoad({ sales_results: salesResults }));
  return salesResults;
};

export const toggleLockSalesResult = (load: Load, saleResultId: string) => (
  dispatch: AppDispatch
) => {
  let isLocked = true;
  const updatedSalesResults = load.sales_results?.map(result => {
    if (result.id === saleResultId) {
      isLocked = result.locked || false;
      return { ...result, locked: !result.locked };
    }
    return result;
  });
  return (isLocked ? unlockLogisticsSaleResult : lockLogisticsSaleResult)(
    load.id,
    saleResultId
  ).then(() => {
    dispatch(mergeLoad({ sales_results: updatedSalesResults }));
  });
};
