import React, { Fragment, useState } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PiggyIcon } from 'icons/piggy.svg';
import { ReactComponent as WeightIcon } from 'icons/weight.svg';
import { ReactComponent as StarIcon } from 'icons/star.svg';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
import Button from 'components/Button';
import StatsBlocks from 'components/StatsBlocks';
import CheckBox from 'components/CheckBox';
import TenantAvatar from 'components/TenantAvatar';
import NumberInput from 'components/Input/NumberInput';
import LabeledInput from 'components/Input/LabeledInput';
import LabelValueRow from 'components/LabelValueRow';
import Loader from 'components/Loader';
import Location from 'components/Location';
import FilterSectionSwitcher from 'components/Filters/FilterSectionSwitcher';
// utils, hooks
import useWeightHelper from 'utils/hooks/useWeightHelper';
import cn from 'classnames';
import { formatDate, formatToTime } from 'utils/formatDateHelper';
// types
import { ContractCommitment, Farm, PackingPlant } from 'types';
import { ContractCommitmentUpdateAttrs } from 'types/handlers';
// styles
import styles from './ContractCommitmentInfo.module.scss';

const statsOptions = [
  {
    label: <FormattedMessage id="contracts.specialty" />,
    value: <StarIcon className={styles['star-icon']} />,
  },
];

type ContractInformationProps = {
  isEditMode: boolean;
  cancelEditMode: () => void;
  commitment: ContractCommitment;
  onUpdate: (attrs: ContractCommitmentUpdateAttrs) => Promise<ContractCommitment>;
  addresses: (PackingPlant | Farm)[];
  className?: string;
};

type StateType = {
  isLoading: boolean;
  headCount: number | null;
  weightMax: number | null;
  weightMin: number | null;
};

const ContractCommitmentInfo = ({
  addresses,
  cancelEditMode,
  commitment,
  className = '',
  isEditMode,
  onUpdate,
}: ContractInformationProps) => {
  const { contract, contract_date, hour, head_count, minute, weight_min, weight_max } = commitment;
  const hasSpecialSections = !!contract?.special_requirements?.sections?.length;
  const {
    formatWeightToDisplay,
    formatWeightToSave,
    getWeightRangeWithLabel,
    weightLabel,
  } = useWeightHelper();
  const [{ isLoading, headCount, weightMax, weightMin }, setData] = useState<StateType>({
    isLoading: false,
    headCount: head_count,
    weightMin: formatWeightToDisplay(weight_min),
    weightMax: formatWeightToDisplay(weight_max),
  });

  const onSave = () => {
    setData(prevState => ({ ...prevState, isLoading: true }));
    onUpdate({
      head_count: headCount,
      weight_max: formatWeightToSave(weightMax),
      weight_min: formatWeightToSave(weightMin),
    })
      .then(updatedCommitment => {
        setData({
          headCount: updatedCommitment.head_count,
          weightMin: formatWeightToDisplay(updatedCommitment.weight_min),
          weightMax: formatWeightToDisplay(updatedCommitment.weight_max),
          isLoading: false,
        });
      })
      .catch(() => {
        setData(prevState => ({ ...prevState, isLoading: false }));
      });
  };

  const onCancel = () => {
    setData(prevState => ({
      ...prevState,
      headCount: commitment.head_count,
      weightMin: formatWeightToDisplay(commitment.weight_min),
      weightMax: formatWeightToDisplay(commitment.weight_max),
    }));
    cancelEditMode();
  };

  return (
    <div
      className={cn(styles['contract-wrapper'], {
        [className]: !!className,
        [styles['is-loading']]: isLoading,
      })}
    >
      <Loader isActive={isLoading} />
      <div className={styles['contract-location']}>
        <div className={styles['contract-name']}>{contract?.name}</div>
        {addresses.map((address, index) => (
          <div key={index}>
            {addresses.length > 1 && `${index + 1}. `}
            <Location name={address.name} city={address.city} state={address.state} />
          </div>
        ))}
        {contract?.special_contract && (
          <div className={styles['stats-info']}>
            <StatsBlocks options={statsOptions} />
          </div>
        )}
      </div>
      <LabelValueRow
        className="mb-5"
        label={<FormattedMessage id="general.contractOwner" />}
        value={
          contract?.owner_company ? (
            <>
              <TenantAvatar
                className="mr-10"
                name={contract.owner_company?.name || ''}
                highlighted
                size="medium"
              />
              {contract.owner_company.name}
            </>
          ) : (
            <FormattedMessage id="general.notAvailableAbbr" />
          )
        }
      />
      <LabelValueRow
        className="mb-5"
        label={<FormattedMessage id="modal.loadModal.fulfillmentDate" />}
        value={formatDate(contract_date)}
        icon={CalendarIcon}
      />
      {hour !== null && minute !== null && (
        <LabelValueRow
          className="mb-5"
          label={<FormattedMessage id="general.dockTime" />}
          value={formatToTime(hour, minute)}
          icon={ClockIcon}
        />
      )}
      {!isEditMode && (
        <LabelValueRow
          className="mb-5"
          label={<FormattedMessage id="general.targetHead" />}
          value={
            headCount ? (
              <FormattedMessage id="general.pigsWithCount" values={{ count: headCount }} />
            ) : (
              <FormattedMessage id="general.notAvailableAbbr" />
            )
          }
          icon={PiggyIcon}
        />
      )}
      {isEditMode && (
        <LabelValueRow
          className="mb-5"
          label={<FormattedMessage id="general.targetHead" />}
          value={
            <NumberInput
              value={headCount}
              onChange={value => setData(prevState => ({ ...prevState, headCount: value ?? null }))}
            >
              {props => (
                <LabeledInput
                  floatRight={false}
                  isFullWidth
                  label={<PiggyIcon width={22} color="#ff5e31" />}
                  placeholder="0"
                  {...props}
                />
              )}
            </NumberInput>
          }
          isValueHovered={false}
          isValueHasPaddings={false}
        />
      )}
      {!isEditMode && (
        <LabelValueRow
          className="mb-5"
          label={<FormattedMessage id="general.targetWeightRange" />}
          value={getWeightRangeWithLabel(weightMin, weightMax)}
          icon={WeightIcon}
        />
      )}
      {isEditMode && (
        <LabelValueRow
          className="mb-5"
          label={<FormattedMessage id="general.targetWeightRange" />}
          isValueHovered={false}
          isValueHasPaddings={false}
          value={
            <Fragment>
              <NumberInput
                value={weightMin}
                onChange={value =>
                  setData(prevState => ({ ...prevState, weightMin: value ?? null }))
                }
                float
                max={9999}
              >
                {props => (
                  <LabeledInput
                    className={styles['weight-input']}
                    floatRight={false}
                    label={<span className={styles.label}>{weightLabel}</span>}
                    labelRight
                    placeholder="0"
                    {...props}
                  />
                )}
              </NumberInput>
              <span className="mh-5"> — </span>
              <NumberInput
                value={weightMax}
                onChange={value =>
                  setData(prevState => ({ ...prevState, weightMax: value ?? null }))
                }
                float
                max={9999}
              >
                {props => (
                  <LabeledInput
                    className={styles['weight-input']}
                    floatRight={false}
                    label={<span className={styles.label}>{weightLabel}</span>}
                    labelRight
                    placeholder="0"
                    {...props}
                  />
                )}
              </NumberInput>
            </Fragment>
          }
        />
      )}
      {isEditMode && (
        <div className={styles.buttons}>
          <Button onClick={onCancel} medium disabled={isLoading}>
            <FormattedMessage id="general.btn.cancel" />
          </Button>
          <Button
            className="ml-10"
            primary
            medium
            onClick={onSave}
            disabled={isLoading || !headCount || !weightMin || !weightMax}
          >
            <FormattedMessage id="general.btn.save" />
          </Button>
        </div>
      )}
      {hasSpecialSections && (
        <div className={styles['special-contract-sections']}>
          <CheckBox
            className={styles['special-contract-checkbox']}
            isChecked
            label={<FormattedMessage id="general.specialContract" />}
            onClick={() => null}
          />
          <div className={styles['sections-list']}>
            {contract?.special_requirements?.sections.map((item, index) => (
              <FilterSectionSwitcher
                key={index}
                isDisabled
                section={{ ...item, sectionIndex: index }}
                onOptionSelect={() => null}
                onRemove={() => null}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractCommitmentInfo;
