import React, { Fragment } from 'react';
// components
import Button from 'components/Button';
import Link from 'components/Link';
import SpecialtyContractStar from 'components/ContractBadge/SpecialtyContractStar';
import LoadModal from 'components/Modal/LoadModal';
import BadgeColumn from './BadgeColumn';
import MultiNotesColumn from './NoteColumn/MultiNotesColumn';
import NoteColumn from './NoteColumn';
import ContractBadge from 'components/ContractBadge';
import UserAvatar from 'components/UserAvatar';
import { FormattedMessage } from 'react-intl';
import CommitmentModal from 'components/Modal/CommitmentModal';
import Tooltip from 'components/Tooltip';
import PortalTooltipColumn from './PortalTooltipColumn';
import ShowMorePortalTooltipColumn from './columnComponents/ShowMorePortalTooltipColumn';
import StatusBadge from 'components/StatusBadge';
import BadgesColumn from './BadgeColumn/BadgesColumn';
import FormattedWeight from 'components/FormattedWeight';
import { ReactComponent as ArchiveIcon } from 'icons/archive.svg';
import { ReactComponent as EditIcon } from 'icons/user-editing.svg';
// hooks
import { useModalSetter } from 'context/useModal';
// utils
import cn from 'classnames';
import { formatDate, formatDateHourMinute } from 'utils/formatDateHelper';
import { formatContractType } from 'utils/contractHelper';
import { getCommitmentType } from 'utils/loadHelper';
// types
import {
  AdditionalRendererData,
  CommitmentType,
  ContractCommitment,
  ExtendedLoad,
  Load,
  RendererType,
  User,
} from 'types';
// styles
import styles from './Columns.module.scss';

type Props = {
  load: Load;
  hasArchiveBadge?: boolean;
  className?: string;
  callback?: () => void;
};

type CommitmentNameRendererProps = {
  commitment: ContractCommitment;
  className?: string;
  callback?: () => void;
};

const LoadModalWithTrigger = ({ load, className, hasArchiveBadge = true, callback }: Props) => {
  const setModal = useModalSetter();
  return (
    <Fragment>
      <Button
        className={cn(styles['load-name'], className)}
        onClick={e => {
          e.stopPropagation();
          setModal(<LoadModal id={load.id} callback={callback} className={styles['load-modal']} />);
        }}
      >
        {load.name}
      </Button>
      {load.edit_loading_id && (
        <Tooltip
          size="small"
          trigger={<EditIcon className={styles.icon} />}
          tooltipContent={<FormattedMessage id="general.editingNow" />}
        />
      )}
      {hasArchiveBadge && load.status === 'incomplete' && load.archived && (
        <Tooltip
          size="small"
          trigger={<ArchiveIcon className={styles.icon} />}
          tooltipContent={<FormattedMessage id="general.archived" />}
        />
      )}
    </Fragment>
  );
};

const CommitmentModalWithTrigger = ({
  commitment,
  className,
  callback,
}: CommitmentNameRendererProps) => {
  const setModal = useModalSetter();
  return (
    <Button
      className={cn(styles['commitment-name'], className)}
      onClick={e => {
        e.stopPropagation();
        setModal(
          <CommitmentModal
            commitment={commitment}
            callback={callback}
            className={styles['commitment-modal']}
          />
        );
      }}
    >
      {commitment.name}
    </Button>
  );
};

export const renderCommitmentName: RendererType<ContractCommitment> = (
  commitment,
  { callback }
) => <CommitmentModalWithTrigger callback={callback} commitment={commitment} />;

export const renderCommitmentNameWithBadge: RendererType<ContractCommitment> = (
  commitment,
  { options, callback }
) => {
  const { contract } = commitment;
  const className = cn({ [styles['color-black']]: options?.textColor === 'black' });

  if (!contract)
    return <CommitmentModalWithTrigger commitment={commitment} className={className} />;

  const { delivery_frequency_type, type, special_contract } = contract;

  return (
    <Fragment>
      <ContractBadge
        size="tiny"
        className="mr-10"
        type={formatContractType(type)}
        frequency={delivery_frequency_type}
      />
      <CommitmentModalWithTrigger
        callback={callback}
        commitment={commitment}
        className={className}
      />
      {special_contract && <SpecialtyContractStar className="ml-5" />}
    </Fragment>
  );
};

export const renderLoadNumber: RendererType<Load> = (load, { callback, options }) => (
  <LoadModalWithTrigger
    load={load}
    hasArchiveBadge={options?.hasArchiveBadge}
    className={cn({ [styles['color-black']]: options?.textColor === 'black' })}
    callback={callback}
  />
);

export const renderLoadType: RendererType<Load> = ({ load_type }) => (
  <FormattedMessage id={`loads.loadType.${load_type}`} />
);

export const renderWeightRange: RendererType<ContractCommitment> = (
  { weight_min, weight_max },
  { emptyPlaceholder }
) => {
  if (!weight_min || !weight_max) return emptyPlaceholder;

  return (
    <BadgeColumn
      data={<FormattedWeight.Range min={weight_min} max={weight_max} />}
      placeholder="N/A"
    />
  );
};

export const renderUserAvatarColumn = (user?: User) => {
  return !user ? null : (
    <UserAvatar size="small" name={user.full_name || ''} srcData={user.profile_photo} />
  );
};

export const renderCommitmentDate: RendererType<ContractCommitment> = ({
  contract_date,
  hour,
  minute,
}: ContractCommitment) => {
  const format = hour === null || minute === null ? 'MM/dd/yy' : 'MM/dd/yy • h:mm a';
  return (
    <BadgeColumn
      data={formatDateHourMinute(contract_date, hour, minute, format)}
      placeholder="N/A"
    />
  );
};

export const renderLoadPickupTime: RendererType<ExtendedLoad> = ({ first_arrive_at }) => {
  return <BadgeColumn data={formatDate(first_arrive_at, 'MM/dd/yy • h:mm a')} placeholder="-" />;
};

export const renderLoadArrivalTime: RendererType<ExtendedLoad> = ({ last_arrive_at }) => {
  return <BadgeColumn data={formatDate(last_arrive_at, 'MM/dd/yy • h:mm a')} placeholder="-" />;
};

export const renderTooltipColumn = (
  value: string | JSX.Element,
  otherData: AdditionalRendererData & { valueMaxWidth?: number }
) => (
  <PortalTooltipColumn
    size="small"
    maxWidth={otherData.valueMaxWidth}
    placeholder={otherData.emptyPlaceholder}
    text={value}
  />
);

// multivalues columns

export const renderShowMoreColumn = (
  values: (string | JSX.Element)[],
  {
    emptyPlaceholder,
    isBadged,
    isUniq,
    valueMaxWidth,
  }: AdditionalRendererData & {
    isBadged?: boolean;
    isUniq?: boolean;
    valueMaxWidth?: number;
  }
) => (
  <ShowMorePortalTooltipColumn
    emptyPlaceholder={emptyPlaceholder}
    items={values}
    itemRenderer={
      isBadged ? item => <BadgeColumn data={item} placeholder={emptyPlaceholder} /> : undefined
    }
    maxWidth={valueMaxWidth}
    shouldBeUniq={isUniq}
  />
);

export const renderTargetHeads: RendererType<Load> = (
  { commitments, load_type },
  { emptyPlaceholder }
) => {
  const commitmentType = getCommitmentType(load_type);
  const filteredCommitments = commitments.filter(
    ({ commitment_type }) => commitment_type === commitmentType
  );
  const targetHeads = filteredCommitments.map(({ contract_commitment, head_count }) =>
    load_type === 'transfer'
      ? (head_count || '').toString()
      : (contract_commitment?.head_count || '').toString()
  );
  if (!targetHeads.length) return <div className="truncated-ellipsis">{emptyPlaceholder}</div>;
  return <BadgesColumn data={targetHeads} placeholder={emptyPlaceholder} />;
};

export const renderScheduledHeads: RendererType<Load> = (
  { commitments, load_type },
  { emptyPlaceholder }
) => {
  const commitmentType = getCommitmentType(load_type);
  const filteredCommitments = commitments.filter(
    ({ commitment_type }) => commitment_type === commitmentType
  );
  const scheduledHeads = filteredCommitments.map(({ head_count }) => (head_count || '').toString());
  if (!scheduledHeads.length) return <div className="truncated-ellipsis">{emptyPlaceholder}</div>;
  return <BadgesColumn data={scheduledHeads} placeholder={emptyPlaceholder} />;
};

export const renderCaregiverStatuses = (type: CommitmentType): RendererType<Load> => (
  { commitments },
  { emptyPlaceholder }
) => {
  return commitments
    .filter(({ commitment_type }) => commitment_type === type)
    .map(({ id, status }) =>
      status ? (
        <StatusBadge key={id} className="mr-5" status={status} />
      ) : (
        <span key={id} className="mr-5">
          {emptyPlaceholder}
        </span>
      )
    );
};

export const renderUserName: RendererType<User> = ({ full_name, profile_photo, id }) => (
  <div className={styles['user-name-column']}>
    <UserAvatar srcData={profile_photo} name={full_name || ''} size="small" />
    <Link to={`/trucking/admin/users/${id}`} className="ml-10">
      {full_name}
    </Link>
  </div>
);

export const renderRole: RendererType<User> = ({ trucking_roles = [] }, { emptyPlaceholder }) => {
  const rolesCount = trucking_roles.length;
  if (!rolesCount) return emptyPlaceholder;

  return trucking_roles.map((role, idx) => (
    <Fragment key={role.id}>
      <FormattedMessage id={`role.${role.name}`} />
      {idx < rolesCount - 1 && ', '}
    </Fragment>
  ));
};

export const renderNote: RendererType<Load> = (
  { internal_comment },
  { emptyPlaceholder, options }
) => {
  return (
    <NoteColumn
      maxWidth={options?.maxWidth}
      note={internal_comment || ''}
      placeholder={emptyPlaceholder}
    />
  );
};

export const renderNotes = (type: CommitmentType): RendererType<Load> => (
  { commitments },
  { emptyPlaceholder, options }
) => {
  const notes = commitments
    .filter(({ commitment_type }) => commitment_type === type)
    .map(lc => ({
      text: lc.comment || '',
      targetName: lc.farm?.name || lc.sow_unit?.name || lc.packing_plant?.name,
    }));
  return notes.length > 1 ? (
    <MultiNotesColumn maxWidth={options?.maxWidth} notes={notes} placeholder={emptyPlaceholder} />
  ) : (
    <NoteColumn
      maxWidth={options?.maxWidth}
      note={notes[0].text || ''}
      placeholder={emptyPlaceholder}
    />
  );
};
