import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LayoutState = {
  sidebar: {
    isCollapsed: boolean;
  };
  moreMenu: {
    isOpened: boolean;
  };
  isMobileHeaderHidden: boolean;
  isOfflineLineVisible: boolean;
};

const initialState: LayoutState = {
  sidebar: {
    isCollapsed: false,
  },
  moreMenu: {
    isOpened: false,
  },
  isMobileHeaderHidden: false,
  isOfflineLineVisible: !navigator.onLine,
};

const authSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSidebarState(state) {
      state.sidebar.isCollapsed = !state.sidebar.isCollapsed;
    },
    setSidebarState(state, action: PayloadAction<boolean>) {
      state.sidebar.isCollapsed = action.payload;
    },
    setMoreMenuState(state, action: PayloadAction<boolean>) {
      state.moreMenu.isOpened = action.payload;
    },
    setIsMobileHeaderHidden(state, action: PayloadAction<boolean>) {
      state.isMobileHeaderHidden = action.payload;
    },
    setIsOfflineLineVisible(state, action: PayloadAction<boolean>) {
      state.isOfflineLineVisible = action.payload;
    },
    resetLayoutData() {
      return initialState;
    },
  },
});

export const {
  resetLayoutData,
  setIsMobileHeaderHidden,
  setIsOfflineLineVisible,
  setSidebarState,
  setMoreMenuState,
  toggleSidebarState,
} = authSlice.actions;

export default authSlice.reducer;
