// utils, renderers
import { getColumns } from 'utils/manageColumnsHelper';
import { addLabelToWeightValue } from 'utils/weightHelper';
import {
  renderCaregiverStatuses,
  renderScheduledHeads,
  renderTargetHeads,
  renderUserAvatarColumn,
  renderLoadType,
  renderLoadNumber,
  renderLoadPickupTime,
  renderLoadArrivalTime,
  renderNote,
  renderNotes,
  renderTooltipColumn,
  renderShowMoreColumn,
} from 'components/VirtualizedTable/columnRenderers';
// types
import { Column, ExtendedLoad, CommitmentTypes, FormatMessageFunc } from 'types';

export const getLoadsColumns = (
  formatMessage: FormatMessageFunc
): Record<string, Column<ExtendedLoad>> => ({
  loadName: {
    label: formatMessage({ id: 'loadsTable.loadNumber' }),
    renderer: renderLoadNumber,
    width: 120,
    sortable: 'name',
    options: {
      hasArchiveBadge: false,
    },
  },
  loadType: {
    label: formatMessage({ id: 'general.type' }),
    renderer: renderLoadType,
    width: 100,
    sortable: 'load_type',
  },
  status: {
    label: formatMessage({ id: 'general.status' }),
    rendererType: 'badgeStatus',
    dataPath: 'status',
    width: 90,
    sortable: 'status',
  },
  truckerStatus: {
    label: formatMessage({ id: 'loadsTable.truckerStatus' }),
    rendererType: 'badgeStatus',
    dataPath: 'trucking_company_status',
    width: 90,
    sortable: 'trucking_company_status',
  },
  departureCaregiverStatus: {
    label: formatMessage({ id: 'loadsTable.departureCaregiverStatus' }),
    renderer: renderCaregiverStatuses(CommitmentTypes.source),
    width: 200,
    emptyPlaceholder: '—',
  },
  arrivalCaregiverStatus: {
    label: formatMessage({ id: 'loadsTable.arrivalCaregiverStatus' }),
    renderer: renderCaregiverStatuses(CommitmentTypes.destination),
    width: 200,
    emptyPlaceholder: '—',
  },
  truckingName: {
    label: formatMessage({ id: 'loadsTable.trucking' }),
    renderer: ({ trucking_company }, data) =>
      renderTooltipColumn(trucking_company?.name || '', { ...data, valueMaxWidth: 160 }),
    width: 160,
  },
  user: {
    label: '',
    emptyPlaceholder: '',
    width: 40,
    renderer: ({ modified_by }) => renderUserAvatarColumn(modified_by),
  },
  pickupTime: {
    label: formatMessage({ id: 'load.pickUpTime' }),
    width: 130,
    renderer: renderLoadPickupTime,
    sortable: 'first_arrive_at',
  },
  arrivalTime: {
    label: formatMessage({ id: 'load.arrivalTime' }),
    width: 130,
    renderer: renderLoadArrivalTime,
    sortable: 'last_arrive_at',
  },
  internalNote: {
    label: formatMessage({ id: 'general.internalNote' }),
    width: 150,
    renderer: renderNote,
    options: {
      maxWidth: 130,
    },
  },
  // multivalues
  destinationNames: {
    label: formatMessage({ id: 'loadsTable.destination' }),
    renderer: ({ destination_entity_names }, data) =>
      renderShowMoreColumn(destination_entity_names || [], {
        ...data,
        isUniq: true,
        valueMaxWidth: 160,
      }),
    sortable: 'destination_entity_names',
    width: 160,
  },
  destinationNotes: {
    label: formatMessage({ id: 'general.destinationNotes' }),
    width: 200,
    renderer: renderNotes('destination'),
    options: {
      maxWidth: 180,
    },
  },
  contractNames: {
    label: formatMessage({ id: 'general.contract' }),
    renderer: ({ contract_names }, data) =>
      renderShowMoreColumn(contract_names || [], {
        ...data,
        isUniq: true,
        valueMaxWidth: 160,
      }),
    sortable: 'contract_names',
    width: 160,
  },
  originNames: {
    label: formatMessage({ id: 'loadsTable.origin' }),
    renderer: ({ source_entity_names }, data) =>
      renderShowMoreColumn(source_entity_names || [], {
        ...data,
        isUniq: true,
        valueMaxWidth: 180,
      }),
    sortable: 'source_entity_names',
    width: 180,
  },
  originNotes: {
    label: formatMessage({ id: 'general.originNotes' }),
    width: 200,
    renderer: renderNotes('source'),
    options: {
      maxWidth: 180,
    },
  },
  targetHeads: {
    label: formatMessage({ id: 'general.targetHead' }),
    renderer: renderTargetHeads,
    emptyPlaceholder: '—',
    width: 100,
  },
  scheduledHeads: {
    label: formatMessage({ id: 'general.headScheduled' }),
    renderer: renderScheduledHeads,
    width: 100,
  },
  avgWeights: {
    label: formatMessage({ id: 'load.avgWeight' }),
    renderer: ({ pig_group_estimated_weight }, data) =>
      renderShowMoreColumn(pig_group_estimated_weight?.map(addLabelToWeightValue) || [], {
        ...data,
        isBadged: true,
      }),
    sortable: 'pig_group_estimated_weight',
    width: 90,
  },
  prelimWeights: {
    label: formatMessage({ id: 'loadsTable.prelimWeight' }),
    renderer: ({ sales_result_avg_pig_weight }, data) =>
      renderShowMoreColumn(sales_result_avg_pig_weight?.map(addLabelToWeightValue) || [], {
        ...data,
        isBadged: true,
      }),
    sortable: 'sales_result_avg_pig_weight',
    width: 130,
  },
  deliveredHeads: {
    label: formatMessage({ id: 'loadsTable.headDelivered' }),
    renderer: ({ sales_results }, data) =>
      renderShowMoreColumn(
        sales_results?.map(({ head_count }) => (head_count || '').toString()) || [],
        { ...data, isBadged: true }
      ),
    emptyPlaceholder: '—',
    width: 100,
  },
  originGroupNames: {
    label: formatMessage({ id: 'loadsTable.originGroup' }),
    renderer: ({ source_pig_group_names }, data) =>
      renderShowMoreColumn(source_pig_group_names || [], {
        ...data,
        isUniq: true,
        valueMaxWidth: 180,
      }),
    sortable: 'source_pig_group_names',
    width: 120,
  },
  destinationGroupNames: {
    label: formatMessage({ id: 'loadsTable.destinationGroup' }),
    renderer: ({ destination_pig_group_names }, data) =>
      renderShowMoreColumn(destination_pig_group_names || [], {
        ...data,
        isUniq: true,
        valueMaxWidth: 180,
      }),
    sortable: 'destination_pig_group_names',
    width: 120,
  },
});

export function getLoadArchiveColumns(fm: FormatMessageFunc) {
  return getColumns(getLoadsColumns(fm), 'loadArchive');
}

export function getLoadResultsColumns(fm: FormatMessageFunc) {
  return getColumns(getLoadsColumns(fm), 'loadResults');
}

export function getScheduledLoadsColumns(fm: FormatMessageFunc) {
  return getColumns(getLoadsColumns(fm), 'loads');
}
