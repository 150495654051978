import React, { Fragment } from 'react';
// components
import { FormattedMessage } from 'react-intl';
// hooks
import useWeightHelper from 'utils/hooks/useWeightHelper';
// types
import { FormattedWeightProps, WeightValue } from './FormattedWeight.types';

const FormattedWeight = ({ value, withoutLabel = false }: FormattedWeightProps) => {
  const { formatWeightToDisplay, getWeightWithLabel } = useWeightHelper();

  if (value === undefined || value === null) {
    return <FormattedMessage id="general.notAvailableAbbr" />;
  }

  const formattedWeight = formatWeightToDisplay(+value);

  if (withoutLabel) {
    return <span>{formattedWeight}</span>;
  }

  return getWeightWithLabel(formattedWeight);
};

const Range = ({ min, max }: { min: WeightValue; max: WeightValue }) => (
  <Fragment>
    <FormattedWeight withoutLabel value={min} />
    &nbsp;-&nbsp;
    <FormattedWeight value={max} />
  </Fragment>
);

FormattedWeight.Range = Range;

export default FormattedWeight;
