import React, { useState } from 'react';
// components
import Portal from 'components/Portal';
// utils
import cn from 'classnames';
// styles
import styles from './Tooltip.module.scss';

type PortalTooltipProps<T> = {
  className?: string;
  content: T;
  isContentCentered?: boolean;
  isThemeReversed?: boolean;
  size: 'small' | 'medium';
  style?: React.CSSProperties;
  triggerBottom: number;
  triggerLeft: number;
  triggerTop: number;
  triggerWidth: number;
};

type HorPosition = 'left' | 'right' | '';
type VerPosition = 'top' | 'bottom' | '';

type PortalTooltipState = {
  horizontalPosition: HorPosition;
  tooltipHeight: number;
  tooltipWidth: number;
  verticalPosition: VerPosition;
};

function PortalTooltip<T>({
  className = '',
  content,
  isContentCentered = false,
  isThemeReversed = false,
  size,
  style,
  triggerBottom,
  triggerLeft,
  triggerTop,
  triggerWidth,
}: PortalTooltipProps<T>) {
  const [
    { horizontalPosition, tooltipHeight, tooltipWidth, verticalPosition },
    setState,
  ] = useState<PortalTooltipState>({
    horizontalPosition: '',
    tooltipHeight: 0,
    tooltipWidth: 0,
    verticalPosition: '',
  });

  const getHorizontalPosition = (tooltipWidth: number): HorPosition => {
    const halfTooltipWidth = tooltipWidth / 2;
    const halfTriggerWidth = triggerWidth / 2;
    const isCutByLeft = halfTooltipWidth > triggerLeft + halfTriggerWidth;
    const isCutByRight = halfTooltipWidth > window.innerWidth - triggerLeft - halfTriggerWidth;
    if (isCutByLeft && !isCutByRight) {
      return 'right';
    }
    if (!isCutByLeft && isCutByRight) {
      return 'left';
    }
    return '';
  };

  const getVerticalPosition = (tooltipHeight: number): VerPosition => {
    const availBottom = window.innerHeight - triggerBottom;
    const canOpenBottom = availBottom > tooltipHeight;
    return canOpenBottom ? 'bottom' : 'top';
  };

  const getTopOffset = () => {
    const halfTooltipWidth = tooltipWidth / 2;
    const halfTriggerWidth = triggerWidth / 2;
    const isCutByLeft = halfTooltipWidth > triggerLeft + halfTriggerWidth;
    const isCutByRight = halfTooltipWidth > window.innerWidth - triggerLeft - halfTriggerWidth;
    const shouldOpenRight = isCutByLeft && !isCutByRight;
    const shouldOpenLeft = isCutByRight && !isCutByLeft;
    if (shouldOpenRight || shouldOpenLeft) {
      const halfTriggerHeight = (triggerBottom - triggerTop) / 2;
      return triggerTop + halfTriggerHeight - tooltipHeight / 2;
    }
    return verticalPosition === 'bottom' ? triggerBottom : triggerTop - tooltipHeight;
  };

  const getLeftOffset = () => {
    if (['top', 'bottom'].includes(verticalPosition)) {
      return triggerLeft + triggerWidth / 2 - tooltipWidth / 2;
    }
    if (horizontalPosition === 'right') {
      return triggerLeft + triggerWidth;
    }
    // we there when horizontalPosition is left
    return triggerLeft - tooltipWidth;
  };

  return (
    <Portal>
      <div
        style={{ ...style, top: getTopOffset(), left: getLeftOffset() }}
        className={cn(styles['portal-tooltip'], {
          [styles.small]: size === 'small',
          [className]: !!className,
          [styles.bottom]: verticalPosition === 'bottom',
          [styles.top]: verticalPosition === 'top',
          [styles.right]: horizontalPosition === 'right',
          [styles.left]: horizontalPosition === 'left',
          [styles['content-centered']]: isContentCentered,
          [styles['is-theme-reversed']]: isThemeReversed,
        })}
        ref={ref => {
          if (ref && !verticalPosition && !horizontalPosition) {
            const tooltipHeight = ref.offsetHeight + 20; // 20 - margins
            const tooltipWidth = ref.offsetWidth + 20;
            const horizontalPosition = getHorizontalPosition(tooltipWidth);
            const verticalPosition = horizontalPosition ? '' : getVerticalPosition(tooltipHeight);
            setState({
              tooltipHeight,
              tooltipWidth,
              horizontalPosition,
              verticalPosition,
            });
          }
        }}
      >
        <div className={styles.triangle} />
        {content}
      </div>
    </Portal>
  );
}

export default PortalTooltip;
