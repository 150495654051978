import React, { Fragment } from 'react';
// components
import Translation from 'components/Translation';
import { FormattedMessage } from 'react-intl';
import EstimatedTravelTimeRow from 'components/EstimatedTravelTimeRow';
import GroupAssignmentViewSection from './GroupAssignmentViewSection';
import LabelValueRow from 'components/LabelValueRow';
import Location from 'components/Location';
import ReadMoreComment from 'components/ReadMoreComment';
import TruncatedText from 'components/TruncatedText';
import WaypointWithLine from 'components/WaypointWithLine';
import { ReactComponent as PiggyIcon } from 'icons/piggy.svg';
import { ReactComponent as ScissorsIcon } from 'icons/scissors.svg';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
// utils
import { getLoadCommitmentAddressEntity } from 'utils/loadHelper';
import { formatDate } from 'utils/formatDateHelper';
// types
import { CommitmentTypes, LoadCommitment, MovementType } from 'types';
// styles
import styles from './TimeLineSection.module.scss';

type TimeLineSectionProps = {
  loadType: MovementType;
  commitments: LoadCommitment[];
};

const TimeLineSection = ({ commitments, loadType }: TimeLineSectionProps) => {
  return (
    <Fragment>
      {commitments.map((item, index) => {
        const {
          arrive_at,
          comment,
          commitment_type,
          cut_level,
          farm,
          head_count,
          id,
          pig_group,
          pig_group_assign_name,
          pig_group_assign_type,
          travel_distance,
          travel_seconds,
        } = item;
        const entity = getLoadCommitmentAddressEntity(item);
        const isDestination = commitment_type === CommitmentTypes.destination;
        const showCutLevel = loadType === 'sale' && !isDestination;
        const isLast = !commitments[index + 1];
        const name = entity?.name || <FormattedMessage id="general.notAvailableAbbr" />;
        return (
          <WaypointWithLine type={commitment_type} isEnd={isLast} key={id}>
            <div>
              <div className="mb-15">
                <TruncatedText
                  maxWidth={350}
                  className={styles.name}
                  text={name}
                  size="small"
                  hasTooltip
                />
                <div className={styles.label}>
                  <Location state={entity?.state} city={entity?.city} />
                </div>
              </div>
              <LabelValueRow
                className="mb-5"
                label={
                  <FormattedMessage id={`load.${isDestination ? 'arrivalDate' : 'pickUpDate'}`} />
                }
                value={formatDate(arrive_at || '')}
                icon={CalendarIcon}
              />
              <LabelValueRow
                className="mb-5"
                label={
                  <FormattedMessage id={`load.${isDestination ? 'arrivalTime' : 'pickUpTime'}`} />
                }
                value={formatDate(arrive_at || '', 'hh:mm a')}
                icon={ClockIcon}
              />
              {showCutLevel && (
                <LabelValueRow
                  className="mb-5"
                  label={<FormattedMessage id="load.cutLevel" />}
                  value={cut_level ? <FormattedMessage id={`cutLevel.${cut_level}`} /> : 'N/A'}
                  icon={ScissorsIcon}
                />
              )}
              <LabelValueRow
                className="mb-5"
                label={<FormattedMessage id="general.targetHeadCount" />}
                value={head_count}
                icon={PiggyIcon}
              />

              {loadType !== 'sale' && isDestination && (
                <GroupAssignmentViewSection
                  farmType={farm?.farm_type}
                  assignType={pig_group_assign_type || 'skip'}
                  customGroupName={pig_group_assign_name || ''}
                  existingGroup={pig_group}
                />
              )}

              {!!comment && (
                <div className="mv-15">
                  <span className="bold">
                    <FormattedMessage id="general.loadNotes" />
                  </span>
                  <div className="mt-10">
                    <Translation text={comment}>
                      <ReadMoreComment comment={comment} />
                    </Translation>
                  </div>
                </div>
              )}

              {farm?.caretaker_availability && (
                <div className="mb-10">
                  <span className="bold">
                    <FormattedMessage id="general.caregiverAvailability" />
                  </span>
                  <div className="pre-text mt-5">{farm.caretaker_availability}</div>
                </div>
              )}

              {!isLast && (
                <EstimatedTravelTimeRow distance={travel_distance} seconds={travel_seconds} />
              )}
            </div>
          </WaypointWithLine>
        );
      })}
    </Fragment>
  );
};

export default TimeLineSection;
