import React, { ReactNode, useEffect } from 'react';
// redux, action creators
import { connect, ConnectedProps } from 'react-redux';
import { validateCurrentUser } from 'store/actionCreators/auth';
// components
import Loader from 'components/Loader';
import SetUser from '../SetUser';
// hooks
import { Route, useHistory } from 'react-router-dom';
// utils
import { initFirebaseMessaging } from 'utils/pushNotificationsHelper';
import { redirectToProd } from 'utils/redirectHelper';
// types
import { RootState } from 'store/reducers/rootReducer';

const connector = connect(
  (state: RootState) => ({
    isLogisticsEnabled: state.auth.user.current_company?.logistics || false,
    isLoggedIn: state.auth.isLoggedIn,
  }),
  { validateCurrentUser }
);

type AuthProps = ConnectedProps<typeof connector> & {
  children: ReactNode;
  isTruckingSystem: boolean;
  isSetUserRoute: boolean;
};

const Auth = ({
  isTruckingSystem,
  isSetUserRoute,
  isLogisticsEnabled,
  isLoggedIn,
  validateCurrentUser,
  children,
}: AuthProps) => {
  const { push } = useHistory();

  useEffect(() => {
    validateCurrentUser()
      .then(user => {
        const { trucking_companies, is_admin } = user;
        if (!is_admin && !isTruckingSystem && !!trucking_companies?.length) {
          push('/trucking');
          return;
        }
        try {
          initFirebaseMessaging();
        } catch (err) {
          console.warn(err.message);
        }
      })
      .catch(() => {
        if (!isSetUserRoute) redirectToProd();
      });
  }, [validateCurrentUser]);

  if (isSetUserRoute) {
    return <Route exact path="/set-user" component={SetUser} />;
  }

  if (isLoggedIn && !isLogisticsEnabled) {
    redirectToProd();
    return <Loader isActive className="align-hor-ver" />;
  }

  if (!isLoggedIn) {
    return <Loader isActive className="align-hor-ver" />;
  }

  return <>{children}</>;
};

export default connector(Auth);
