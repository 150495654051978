import { Translations } from 'types';
import { makeAxiosRequestV1Api } from 'utils/api';

const languageMapping = {
  en: 'en',
  'es-CO': 'es',
};

export const getTranslation = (text: string, language: string): Promise<Translations> => {
  return makeAxiosRequestV1Api('/translation/translate_text', {
    method: 'POST',
    data: {
      resource: {
        text,
        language: languageMapping[language as keyof typeof languageMapping] || language,
      },
    },
  }).then(response => response.resource);
};
